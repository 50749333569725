<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-end">
    <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-font-medium">
    <h3 class="tw-text-modal-title tw-text-3sm">{{ 'user_management.actions.change_password.modal_title' | translate }}</h3>
  </div>

  <form [formGroup]="changePassForm">
    <div class="tw-flex tw-flex-col tw-justify-center tw-my-5">
      <div class="tw-flex tw-flex-row tw-relative">
        <div class="tw-w-full">
          <input [type]="showPassword ? 'text' : 'password'" class="tw-w-full tw-rounded-md tw-border-2 tw-border-input-border focus:tw-ring-0" formControlName="password" [placeholder]="'user_management.actions.change_password.new_password' | translate">
        </div>
        <div class="tw-absolute tw-top-3 tw-right-5 tw-cursor-pointer" *ngIf="passwordControl.value.length > 0">
          <i class="fas fa-eye{{showPassword ? '-slash' : ''}}" (click)="showPassword = !showPassword"></i>
        </div>
      </div>
      <div class="tw-flex tw-flex-row tw-relative tw-mt-2">
        <div class="tw-w-full">
          <input [type]="showRepeatPassword ? 'text' : 'password'" class="tw-w-full tw-rounded-md tw-border-2 tw-border-input-border focus:tw-ring-0" formControlName="repeatPassword" [placeholder]="'user_management.actions.change_password.confirm_password' | translate">
        </div>
        <div class="tw-absolute tw-top-3 tw-right-5 tw-cursor-pointer" *ngIf="repeatPasswordControl.value.length > 0">
          <i class="fas fa-eye{{showRepeatPassword ? '-slash' : ''}}" (click)="showRepeatPassword = !showRepeatPassword"></i>
        </div>
      </div>
    </div>
  </form>

  <div class="tw-flex tw-justify-center">
    <mh-core-lib-button [isDefault]="true" width=200 [isDisabled]="!changePassForm.valid || passwordControl?.value !== repeatPasswordControl?.value" [text]="('user_management.actions.change_password.title' | translate)" (click)="changePassword()"></mh-core-lib-button>
  </div>

</div>

