import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mh-team-areas',
  templateUrl: './team-areas.component.html',
  styles: [
  ]
})
export class TeamAreasComponent {
  @Input() areas = [];
  @Input() selectedAreas = [];

  @Output() updateAreas = new EventEmitter();

  displayChipsFn(option) {
    return option?.name;
  }

  inputTagFilter(options, value) {
    return options.filter((option) => option?.name?.toLowerCase().includes(value));
  }

  handleAreasInput(options) {
    this.selectedAreas = options;
    this.updateAreas.next(this.selectedAreas);
  }
}
