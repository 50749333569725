<button  *ngIf="showHeaderExitButton" class="tw-text-2xs tw-text-notification-text tw-bg-gray-300 tw-rounded-full tw-w-16 tw-h-5 tw-p-[1px]" (click)="goToBack()">{{'commons.exit' | translate }}</button>

<ng-container *ngIf="personalAdjusts; else userConfig">
    <mh-core-lib-tabs *ngIf="!loading" [className]="'!tw-px-10 !tw-py-3'" [selectedTab]="selectedIndex">
        <mh-core-lib-tab [title]="'user_management.actions.edit.data' | translate" *ngIf="userEmail">
            <div class="tw-mt-5 tw-rounded-xl tw-bg-white tw-p-14">
                <mh-personal-information [data]="user" *ngIf="user" (updateUser)="user = $event" [personalAdjusts]="true" (updateUser)="saveUser($event)"></mh-personal-information>
            </div>
        </mh-core-lib-tab>
    </mh-core-lib-tabs>
</ng-container>

<ng-template #userConfig>
    <article *ngIf="!loading" class="tw-w-4/5">
        <mh-core-lib-tabs [className]="'!tw-px-10 !tw-py-3'" [selectedTab]="selectedIndex">
            <mh-core-lib-tab [title]="'user_management.actions.edit.data' | translate" *ngIf="userEmail">
                <div class="tw-mt-5 tw-rounded-xl tw-bg-white tw-p-14">
                    <mh-personal-information [data]="user" *ngIf="user" (updateUser)="user = $event" (updateUser)="saveUser($event)"></mh-personal-information>
                </div>
            </mh-core-lib-tab>
            <mh-core-lib-tab [title]="'user_management.actions.edit.properties' | translate">
                <div class="tw-mt-5 tw-rounded-xl tw-bg-white tw-p-14">
                    <mh-accesses-table 
                        [dataCustomers]="customers"
                        [chains]="chains"
                        [userCustomers]="user?.customers_ids"
                        *ngIf="customers?.length > 0 && chains?.length > 0"
                        (updateHotelsToDelete)="hotelsToDelete = $event"
                        (updateHotelsSelected)="hotelsSelected= $event"
                        (updateIndex)="saveProperties()"></mh-accesses-table>
                </div>
            </mh-core-lib-tab>
            <mh-core-lib-tab [title]="'user_management.actions.edit.accesses' | translate">
                <div class="tw-mt-5 tw-rounded-xl tw-bg-white tw-p-14">
                    <mh-user-accesses [config]="userAccesses" (saveConfig)="saveUserAccesses($event)"></mh-user-accesses>
                </div>
            </mh-core-lib-tab>
        </mh-core-lib-tabs>
    </article>
</ng-template>