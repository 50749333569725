import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import { EditProductService } from 'app/configuration/edit/edit-product.service';
import { DeskConfig, ReportPeriodBase } from '../Profile.model';
import { UtilService } from 'app/shared/util.service';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { ProductsService } from 'app/_services';
import { CoreLibGenericConfirmationModalComponent } from 'app/core-lib/dialogs/generic-confirmation-modal/generic-confirmation-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { CustomersState } from 'app/states/customers/customers.state';
import { Select } from '@ngxs/store';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';

@Component({
  selector: 'mh-profile-configuration',
  templateUrl: './profile-configuration.component.html',
  styleUrls: ['./profile-configuration.component.scss'],
  providers: [EditProductService],
})
export class ProfileConfigurationComponent implements OnInit, OnDestroy {
  @Select(CustomersState.currentCustomer) currentCustomerChanged$: Observable<any[]>;

  @Input()
  customer;

  @Input()
  user;

  @Input()
  type;

  @Input()
  icon;

  @Input()
  title;

  @Input()
  style;

  @Input()
  currentProfile;

  @Input()
  is_super_admin;

  @Input()
  is_admin;

  @Input()
  userIds;

  @Input()
  isGlobal = false;

  @Input()
  setupType;

  usersNumber = 0;
  product = '';
  loading = false;
  otas: any;
  selectedIndex = 0;
  subscriptions = new Subscription();
  currentLanguage = this.utilService.getCurrentLanguage();
  deskGlobalConfigs: DeskConfig[];
  deskConfig;
  reportsConfigData;
  editMassiveAlerts = [];
  editMassiveReports = false;
  accordionReportItems: ReportPeriodBase[] = [
    {
      title: 'summaries',
      expanded: true,
      allChecked: false,
      items: [
        { value: 'daily', check: false, enable: true },
        { value: 'weekly', check: false, enable: true },
        { value: 'monthly', check: false, enable: true },
      ],
    },
  ];
  accordionAlertItems = [
    {
      title: 'FollowUp',
      id: 1,
      type: 'nested',
      allChecked: [],
      items: [],
      active: false,
    },
    {
      title: 'OnSite',
      id: 2,
      type: 'nested',
      allChecked: [],
      items: [],
      active: false,
    },
    {
      title: 'Online',
      id: 3,
      type: 'nested',
      allChecked: [true, true],
      items: [],
      active: false,
    },
    {
      title: 'Desk',
      id: 6,
      type: 'simple',
      allChecked: [true, true],
      items: [],
      active: false,
    },
  ];

  npsOptions = [
    { name: 'Detractor', check: false, value: 'detractor' },
    { name: 'Pasivo', check: false, value: 'passive' },
    { name: 'Promotor', check: false, value: 'promoter' },
  ];

  sources = [
    { id: 1, name: 'Email', value: false, enable: true, tooltip: 'notifications.manager.tooltips.email' },
    { id: 2, name: 'Web/App', value: false, enable: true, tooltip: 'notifications.manager.tooltips.fs' },
    { name: 'SMS', value: false, enable: false },
    { name: 'Whatsapp', value: false, enable: false },
    { name: 'Todos', value: false, enable: false },
  ];

  sourcesReports = [
    { id: 1, name: 'Email', value: false, enable: true, tooltip: 'notifications.manager.tooltips.email' },
  ];

  readonly ALERTS_CONFIG_PAGES = {
    personal_adjusts: 0,
    alerts: 1,
    reports: 2,
  };

  readonly LANG_CODES = {
    es: 0,
    en: 1,
    pt: 2,
  };

  readonly DESK_CONFIGS = [
    'flag_change_person_in_charge',
    'flag_new_case',
    'flag_new_comment',
    'flag_new_subscribed',
    'flag_priority_change',
    'flag_status_change',
  ];

  readonly PRODUCTS = {
    FOLLOWUP: 1,
    ONSITE: 2,
    ONLINE: 3,
    DESK: 6,
  };
  userPersonalInformation: any;
  tab: string;
  userEmailParam: string;

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private editProductsService: EditProductService,
    private utilService: UtilService,
    private productService: ProductsService,
    private modalService: ModalService,
    private translate: TranslateService,
    private location: Location,
    private router: Router,
    private alertService: AlertService,
    private usersManagementService: UsersManagmentService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.utilService.isCustomerChanged().subscribe(() => {
        this.resetData();
        this.init();
      }),
    );
    this.subscriptions.add(
      this.utilService.isLanguageChanged().subscribe((lang) => {
        this.currentLanguage = lang;
        this.getSurveysAlerts();
      }),
    );

    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const queryParams: ParamMap = this.route.snapshot.queryParamMap;
          this.userIds = queryParams.get('users');
          this.isGlobal = Boolean(queryParams.get('isGlobal'));
          this.setupType = queryParams.get('setupType');
          this.tab = queryParams.get('tab');
          this.userEmailParam = queryParams.get('userEmail');
          this.checkTabIndex();
          this.resetData();
          this.init();
        }
      }),
    );
    this.init();
  }

  async init() {
    if (this.userIds && !Array.isArray(this.userIds)) {
      this.userIds = this.userIds.split(',');
      this.usersNumber = this.userIds?.length;
    } else {
      this.usersNumber = 0;
    }
    this.sources = this.sources.filter((source) => source.enable);
    this.checkTabIndex();
    this.getData();
    try {
      this.userPersonalInformation = await this.usersManagementService.findUser(this.userEmailParam).toPromise();
    } catch (error) {
      console.error('error fetching user info', error);
    }
  }

  getData() {
    this.editMassiveAlerts = [];
    this.editMassiveReports = false;
    if (this.productService.hasFollowup || this.productService.hasOnsite || this.isGlobal) {
      this.accordionAlertItems.find((item) => item.id === this.PRODUCTS.FOLLOWUP).active =
        this.productService.hasFollowup || this.isGlobal;
      this.accordionAlertItems.find((item) => item.id === this.PRODUCTS.ONSITE).active =
        this.productService.hasOnsite || this.isGlobal;
      this.getSurveysAlerts();
    }

    if (this.productService.hasOnline || this.isGlobal) {
      this.accordionAlertItems.find((item) => item.id === this.PRODUCTS.ONLINE).active = true;
      this.getOtasAlerts();
    }

    if (this.productService.hasDesk || this.isGlobal) {
      this.accordionAlertItems.find((item) => item.id === this.PRODUCTS.DESK).active = true;
      this.getDeskAlerts();
    }
    this.getPeriodsReport();
  }

  checkTabIndex() {
    const params = this.route.snapshot.queryParams;
    this.userEmailParam = params?.userEmail;
    if (this.setupType) {
      this.selectedIndex = this.setupType === 'alert_setup' ? 1 : 2;
    } else {
      const { tab } = params;
      this.selectedIndex = +this.ALERTS_CONFIG_PAGES[tab] || 0;
    }
  }

  getSurveysAlerts() {
    const userId = this.userIds?.length > 0 ? this.userIds[0] : this.user.id;
    this.loading = true;
    this.accordionAlertItems.map((item) => (item.items = []));
    const productsForFetch = this.accordionAlertItems.filter(
      (item) => item.active && [this.PRODUCTS.FOLLOWUP, this.PRODUCTS.ONSITE].includes(item.id),
    );
    productsForFetch.map((product) => {
      if (this.isGlobal) {
        product.type = 'simple';
        product.items = this.npsOptions.map((option) => {
          return {
            name: option.name,
            value: option.value,
            type: 'simple',
            channels: [
              {
                check: false,
                id_alert_channel: 1,
                name: option.value,
              },
              {
                check: false,
                id_alert_channel: 2,
                name: option.value,
              },
            ],
          };
        });
        this.loading = false;
      } else {
        this.editProductsService.getSurveyAlerts(this.customer.id, userId, product.id).subscribe((res) => {
          if (res) {
            const surveys: any = res;
            if (surveys[0].survey.product_id === product.id) {
              surveys.forEach((survey) => {
                const itemToFind = product.items.find((item) => item.surveyId === survey.survey.id);
                if (this.usersNumber > 1) {
                  this.npsOptions
                    .map((nps) => nps.value)
                    .forEach((nps) => {
                      survey[nps] = false;
                    });
                }

                if (!itemToFind) {
                  product.items.push({
                    name: survey.survey.translates[this.LANG_CODES[this.currentLanguage]].title,
                    surveyId: survey.survey.id,
                    channels: [
                      {
                        ...survey,
                        allChecked: survey.promoter && survey.detractor && survey.passive,
                      },
                    ],
                  });
                } else {
                  itemToFind.channels.push({
                    ...survey,
                    allChecked: survey.promoter && survey.detractor && survey.passive,
                  });
                }
              });
            }
          }

          const flattenItems = product.items.map((item) => item.channels).reduce((acc, val) => acc.concat(val), []);
          product.allChecked = this.sources.map((source) => {
            const filterItems = flattenItems.filter((item) => item.id_alert_channel === source.id);
            return filterItems.every((item) => item.allChecked);
          });
          this.loading = false;
        });
      }
    });
  }

  async getOtasAlerts() {
    const userId = this.userIds?.length > 0 ? this.userIds[0] : this.user.id;
    this.loading = true;
    try {
      if (this.isGlobal) {
        const onlineData = this.accordionAlertItems.find((product) => product.id === 3);
        onlineData.type = 'simple';
        onlineData.items = this.npsOptions.map((option) => {
          return {
            name: option.name,
            value: option.value,
            type: 'simple',
            channels: [
              {
                check: false,
                id_alert_channel: 1,
                name: option.value,
              },
              {
                check: false,
                id_alert_channel: 2,
                name: option.value,
              },
            ],
          };
        });
        onlineData.allChecked = [false, false];
        this.loading = false;
      } else {
        const resp = await this.editProductsService.getOtasAlerts(this.customer.id, userId).toPromise();
        this.otas = resp;
        if (this.otas) {
          const otaIds = Array.from(new Set(this.otas.map((item) => item.ota.id)));
          const onlineData = this.accordionAlertItems.find((item) => item.id === this.PRODUCTS.ONLINE);
          this.otas.forEach((ota) => {
            if (this.usersNumber > 1) {
              this.npsOptions
                .map((nps) => nps.value)
                .forEach((nps) => {
                  ota[nps] = false;
                });
            }
            const channelId = ota.id_alert_channel;
            onlineData.allChecked[channelId - 1] = this.isGlobal
              ? false
              : onlineData.allChecked[channelId - 1] && ota.promoter && ota.detractor && ota.passive;
          });
          otaIds.map((otaId) => {
            const otas = this.otas.filter((item) => item.ota.id === otaId);
            onlineData.items.push({ id: otaId, name: otas[0].ota.name, channels: otas });
          });
        }
        this.loading = false;
      }
    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  }

  async getDeskAlerts() {
    const userId = this.userIds?.length > 0 ? this.userIds[0] : this.user.id;
    this.loading = true;
    try {
      this.deskGlobalConfigs = await this.editProductsService.getDeskAlerts(this.customer.id, userId).toPromise();
      this.deskConfig = this.accordionAlertItems.find((item) => item.id === this.PRODUCTS.DESK);
      if (this.deskGlobalConfigs) {
        this.deskConfig.items = this.DESK_CONFIGS.map((configLabel) => {
          const name = configLabel;

          const channels = this.sources.map((source) => {
            const configFound = this.deskGlobalConfigs.find((config) => config.id_alert_channel === source.id);
            if (source.id === 2) {
              this.deskConfig.allChecked[source.id - 1] = true;
              return {
                name,
                check: true,
                id_alert_channel: source.id,
              };
            } else {
              this.deskConfig.allChecked[source.id - 1] =
                this.deskConfig.allChecked[source.id - 1] &&
                (this.usersNumber > 1 || this.isGlobal ? false : configFound[configLabel]);
              return {
                name,
                check: this.usersNumber > 1 || this.isGlobal ? false : configFound[configLabel],
                id_alert_channel: source.id,
              };
            }
          });

          return {
            name,
            channels,
            translationLabel: 'notifications.manager.' + name,
            translation: true,
            type: this.deskConfig.type,
          };
        });
      }
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }

  async getPeriodsReport() {
    const userId = this.userIds?.length > 0 ? this.userIds[0] : this.user.id;
    try {
      const payload = {
        user_id: userId,
        customer_id: this.customer.id,
      };
      const resp: any = await this.profileService.getPeriodReport(payload).toPromise();
      this.accordionReportItems[0].items.forEach((item) => {
        item.check = this.usersNumber > 1 ? false : resp.data[`flag_${item.value}`];
        if (item.value === 'daily' && !this.isSuperAdmin) item.enable = false;
      });

      if (this.accordionReportItems[0].items.filter((item) => item.enable).every((item) => item.check)) {
        this.accordionReportItems[0].allChecked = true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onAccordionDataChanged(e) {
    this.editMassiveReports = e[0].items.some((item) => item.check);
    const userId = this.userIds?.length === 1 ? this.userIds[0] : this.user.id;
    if (e[0].items.filter((item) => item.enable).every((item) => item.check)) {
      e[0].allChecked = true;
    }
    const payload = {
      [this.userIds?.length === 1 || !this.userIds ? 'user_id' : 'user_ids']: userId,
      customer_id: this.customer.id,
    };
    e.forEach((item) => {
      item.items.forEach((subItem) => {
        payload[`flag_${subItem.value}`] = subItem.enable ? subItem.check : false;
      });
    });
    this.reportsConfigData = payload;
    if (this.userIds?.length === 1 || !this.userIds) await this.profileService.setPeriodReport(payload).toPromise();
  }

  async onNestedAccordionDataChanged(e) {
    const { alertItem, alertSubItem } = e;

    this.loading = true;
    if (this.isGlobal || this.userIds?.length > 1) {
      const fetchData = this.sources.map(({ id }) => {
        const data = alertItem.items.reduce(
          (acc, curr) => {
            const channelData = curr.channels.find((channel) => channel.id_alert_channel === id);
            return {
              ...acc,
              [channelData.name]: channelData.check,
            };
          },
          {
            user_ids: this.userIds.join(','),
            product_id: alertItem.id,
            id_alert_channel: id,
            active: true,
          },
        );
        return this.editProductsService.editMassiveSurveyAlerts(alertItem.id, data);
      });

      const editData = this.editMassiveAlerts.find((edit) => edit.id === alertItem.id);
      if (!editData) {
        this.editMassiveAlerts.push({
          id: alertItem.id,
          fetchData,
        });
      } else {
        editData.fetchData = fetchData;
      }
      this.loading = false;
    } else {
      const userId = this.userIds?.length > 0 ? this.userIds[0] : this.user.id;

      try {
        if (alertItem.type === 'nested') {
          let payload;
          if (!alertSubItem) {
            payload = alertItem.items.map((item) => item.channels).flat();
          } else {
            payload = [alertSubItem];
          }

          alertItem.items.forEach((item) => {
            item.active = item.passive || item.detractor || item.promoter;
          });
          if (alertItem.id === this.PRODUCTS.FOLLOWUP || alertItem.id === this.PRODUCTS.ONSITE) {
            if (this.userIds?.length > 0) {
              this.userIds.forEach(async (id) => {
                await this.editProductsService
                  .editSurveyAlerts(this.customer.id, id, alertItem.id, payload)
                  .toPromise();
              });
            } else {
              await this.editProductsService
                .editSurveyAlerts(this.customer.id, userId, alertItem.id, payload)
                .toPromise();
            }
          } else {
            if (this.userIds?.length > 0) {
              this.userIds.forEach(async (id) => {
                await this.editProductsService.editOtasAlerts(this.customer.id, id, payload).toPromise();
              });
            } else {
              await this.editProductsService.editOtasAlerts(this.customer.id, userId, payload).toPromise();
            }
          }
          this.loading = false;
          return;
        } else {
          if (alertItem.id === this.PRODUCTS.DESK) {
            let payload;
            if (!alertSubItem) {
              payload = this.sources.map((source) => {
                const deskConfigs = this.DESK_CONFIGS.reduce((acc, curr) => {
                  const deskConfig = alertItem.items.find((item) => item.name === curr).channels[source.id - 1];
                  return {
                    ...acc,
                    [curr]: deskConfig.check,
                  };
                }, {});
                return {
                  active: alertItem.id === this.PRODUCTS.DESK && source.id === 1,
                  customer_id: this.customer.id,
                  user_id: userId,
                  id_alert_channel: source.id,
                  ...deskConfigs,
                };
              });
            } else {
              payload = [
                {
                  active: alertItem.id === this.PRODUCTS.DESK && alertSubItem.id_alert_channel === 1,
                  customer_id: this.customer.id,
                  user_id: userId,
                  id_alert_channel: alertSubItem.id_alert_channel,
                  [alertSubItem.name]: alertSubItem.check,
                },
              ];
            }
            if (this.userIds?.length > 0) {
              this.userIds.forEach(async (id) => {
                payload[0].user_id = id;
                await this.editProductsService.editDeskAlerts(this.customer.id, id, payload).toPromise();
              });
            } else {
              await this.editProductsService.editDeskAlerts(this.customer.id, userId, payload).toPromise();
            }
            this.loading = false;
            return;
          }
        }
        const payload = {
          user_id: userId,
          customer_id: this.customer.id,
          active: alertItem.items.some((item) => item.check),
          survey: alertItem.survey,
        };
        alertItem.items.forEach((element) => {
          payload[element.value] = element.check;
        });
        await this.editProductsService.editSurveyAlerts(this.customer.id, userId, alertItem.id, [payload]).toPromise();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    }
  }

  saveMassiveAlerts() {
    const config = {
      data: {
        text: this.translate.instant('notifications.manager.confirm_massive_alerts'),
      },
    };
    const ref = this.modalService.open(CoreLibGenericConfirmationModalComponent, config);
    this.subscriptions.add(
      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe((response) => {
          if (response) {
            this.loading = true;
            const fetch = this.editMassiveAlerts
              .map((data) => data.fetchData)
              .reduce((acc, val) => acc.concat(val), []);

            forkJoin(fetch).subscribe(
              () => {
                this.alertService.handleAlert(
                  'user_management.actions.success',
                  5000,
                  'bottom',
                  'end',
                  'snackbar-panel-success-user-config',
                );
                this.editMassiveAlerts = [];
                this.loading = false;
              },
              () => {
                this.loading = false;
              },
            );
          }
        }),
    );
  }

  async saveMassiveReports() {
    try {
      const payload = {
        ...this.reportsConfigData,
        user_ids: this.userIds.join(','),
      };
      if (this.isGlobal) delete payload.customer_id;

      const config = {
        data: {
          text: this.translate.instant('notifications.manager.confirm_massive_alerts'),
        },
      };
      const ref = this.modalService.open(CoreLibGenericConfirmationModalComponent, config);
      this.subscriptions.add(
        ref
          .afterClosed()
          .pipe(take(1))
          .subscribe(async (response) => {
            if (response === 'ACCEPT') {
              this.loading = true;
              const res: any = await this.editProductsService.editMassiveReports(payload).toPromise();
              if (res.status === 'OK') {
                this.editMassiveReports = false;
                this.alertService.handleAlert(
                  'user_management.actions.success',
                  5000,
                  'bottom',
                  'end',
                  'snackbar-panel-success-user-config',
                );
              }
              this.loading = false;
            }
          }),
      );
    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  }

  resetData() {
    this.accordionReportItems = [
      {
        title: 'summaries',
        expanded: true,
        allChecked: false,
        items: [
          { value: 'daily', check: false, enable: true },
          { value: 'weekly', check: false, enable: true },
          { value: 'monthly', check: false, enable: true },
        ],
      },
    ];
    this.accordionAlertItems = [
      {
        title: 'FollowUp',
        id: 1,
        type: 'nested',
        allChecked: [],
        items: [],
        active: false,
      },
      {
        title: 'OnSite',
        id: 2,
        type: 'nested',
        allChecked: [],
        items: [],
        active: false,
      },
      {
        title: 'Online',
        id: 3,
        type: 'nested',
        allChecked: [true, true],
        items: [],
        active: false,
      },
      {
        title: 'Desk',
        id: 6,
        type: 'simple',
        allChecked: [true, true],
        items: [],
        active: false,
      },
    ];
    this.npsOptions = [
      { name: 'Detractor', check: false, value: 'detractor' },
      { name: 'Pasivo', check: false, value: 'passive' },
      { name: 'Promotor', check: false, value: 'promoter' },
    ];

    this.sources = [
      { id: 1, name: 'Email', value: false, enable: true, tooltip: 'notifications.manager.tooltips.email' },
      { id: 2, name: 'Fidelity', value: false, enable: true, tooltip: 'notifications.manager.tooltips.fs' },
      { name: 'SMS', value: false, enable: false },
      { name: 'Whatsapp', value: false, enable: false },
      { name: 'Todos', value: false, enable: false },
    ];
    this.sourcesReports = [
      { id: 1, name: 'Email', value: false, enable: true, tooltip: 'notifications.manager.tooltips.email' },
    ];
  }

  saveUser(user) {
    this.usersManagementService.updateUser(user, user.id).subscribe(
      (newInfoUser) => {
        this.user = newInfoUser;
        this.alertService.handleAlert(
          'user_management.actions.success',
          5000,
          'bottom',
          'end',
          'snackbar-panel-success-user-config',
        );
      },
      () => {
        this.alertService.handleAlert('user_management.actions.error', 5000, 'bottom', 'end', 'snackbar-panel-error');
      },
    );
  }

  get canSetDemo() {
    return this.currentProfile.role !== 'mh-demo-admin' && this.is_super_admin;
  }

  goToBack() {
    this.location.back();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get isSuperAdmin() {
    return this.utilService.currentUser.isSuperAdmin();
  }
}
