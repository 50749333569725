import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UserProfile } from 'app/shared/model';

@Component({
  selector: 'mh-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  @Input() actions = ['disable_user', 'restore_access', 'alert_setup', 'assign_properties', 'delete_access'];
  open = false;

  @Input() smallButton = true;

  @Input()
  user: UserProfile;

  @Output()
  actionClicked: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  constructor(private eRef: ElementRef) {}

  ngOnInit(): void {
  }

  clickAction(action) {
    this.actionClicked.next({action, user: this.user});
    this.open = !this.open;
  }

  get actionsForRole() {
    return ['disable_user', 'edit'];
  }
}
