import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { UtilService } from 'app/shared/util.service';
import { CustomersState } from 'app/states/customers/customers.state';
import { Observable, Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'mh-user-config',
  templateUrl: './user-config.component.html',
})
export class UserConfigComponent implements OnInit, OnDestroy {
  @Select(CustomersState.currentCustomer) currentCustomerChanged$: Observable<any[]>;
  readonly showHeaderExitButton = false;
  selectedIndex = 0;
  userEmail;
  user;
  userIds;
  chains = [];
  customers = [];
  userAccesses;
  hotelsToDelete = [];
  hotelsSelected = [];
  personalAdjusts = false;
  subscriptions = new Subscription();
  firstCallDone = false;
  loading= false;
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private usersManagementService: UsersManagmentService,
    private utilService: UtilService,
    private alertService: AlertService,
  ) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      this.loading = true;
      this.userEmail = params?.userEmail;
      this.userIds = params?.userIds;
      this.personalAdjusts = Boolean(params?.personalAdjust);

      if (this.userEmail) {
        this.user = await this.usersManagementService.findUser(this.userEmail).toPromise();
        this.userAccesses = await this.usersManagementService.getUserAccesses(this.user.id).toPromise();
      } else {
        this.userAccesses = await this.usersManagementService.getUserAccesses(this.userIds).toPromise();
      }
      this.loading = false;
    });

    this.usersManagementService.getAllSimpleCustomers().subscribe((result: any) => {
      if (this.utilService.currentUser.isSuperAdmin()) {
        this.customers = result;
      } else {
        const currentUserCustomerIds = this.utilService.currentUser.customers.map((customer) => customer.id);
        this.customers = result.filter((customer) => currentUserCustomerIds.includes(customer.id));
      }

      this.chains = this.customers.reduce((prev, curr) => {
        const chainToPush = curr.chain_property?.chain_id;
        if (chainToPush && !prev.find((chain) => chain.id === chainToPush.id)) {
          prev = [...prev, chainToPush];
        }
        return prev;
      }, []);
    });

    this.subscriptions = this.currentCustomerChanged$.subscribe(() => {
      if (this.firstCallDone) {
        this.goToBack();
      } else {
        this.firstCallDone = true;
      }
    });
  }

  goToBack() {
    this.location.back();
  }

  saveUser(user) {
    this.usersManagementService.updateUser(user, user.id).subscribe(
      (newInfoUser) => {
        this.user = newInfoUser;
        this.alertService.handleAlert(
          'user_management.actions.success',
          5000,
          'bottom',
          'end',
          'snackbar-panel-success-user-config',
        );
      },
      () => {
        this.alertService.handleAlert('user_management.actions.error', 5000, 'bottom', 'end', 'snackbar-panel-error');
      },
    );
  }

  saveProperties() {
    let obsArr = {};
    this.hotelsSelected = this.hotelsSelected.filter(Boolean);
    this.hotelsToDelete = this.hotelsToDelete.filter(Boolean);
    if (this.hotelsSelected?.length > 0) {
      obsArr = {
        add: this.usersManagementService.assign_properties(
          this.userEmail ? this.user.id : this.userIds,
          this.hotelsSelected.map((hotel) => hotel.id),
        ),
      };
    }

    if (this.hotelsToDelete?.length) {
      obsArr = {
        ...obsArr,
        delete: this.usersManagementService.revokeProperties(
          this.userEmail ? this.user.id : this.userIds,
          this.hotelsToDelete.map((hotel) => hotel.id),
        ),
      };
    }

    if (Object.keys(obsArr)?.length > 0) {
      forkJoin(obsArr).subscribe(
        () => {
          this.alertService.handleAlert(
            'user_management.actions.success',
            5000,
            'bottom',
            'end',
            'snackbar-panel-success-user-config',
          );
        },
        () => {
          this.alertService.handleAlert('user_management.actions.error', 5000, 'bottom', 'end', 'snackbar-panel-error');
        },
      );
    }
  }

  saveUserAccesses(config) {
    this.usersManagementService.addUserAccesses(config, this.userEmail ? this.user.id : this.userIds).subscribe(
      () => {
        this.alertService.handleAlert(
          'user_management.actions.success',
          5000,
          'bottom',
          'end',
          'snackbar-panel-success-user-config',
        );
      },
      () => {
        this.alertService.handleAlert('user_management.actions.error', 5000, 'bottom', 'end', 'snackbar-panel-error');
      },
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
