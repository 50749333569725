<div class="tw-flex tw-justify-between tw-mb-10" *ngIf="progressRate">
  <div>
    <p class="tw-text-xl tw-font-semibold" [innerHTML]="'user_management.create_user.user_accesses_title' | translate"></p>
  </div>
  <div class="tw-flex tw-gap-1">
    <div class="tw-mb-5 tw-h-2 tw-rounded-full tw-bg-gray-200 tw-w-80 tw-mt-[10px]">
      <div class="tw-h-2 tw-rounded-full tw-bg-[#0F93CC] tw-w-[75%]"></div>
    </div>
    <p class="tw-text-xl">{{ progressRate }}%</p>
  </div>
</div>

<cdk-accordion class="tw-block" *ngFor="let item of configMenu; let index = index; trackBy: trackByItem">
    <cdk-accordion-item
        #accordionItem="cdkAccordionItem"
        class="tw-block tw-bg-white tw-mt-4 tw-shadow-2"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-' + index"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + index"
    >
        <div
          class="tw-flex tw-content-center tw-justify-between tw-p-4 hover:tw-cursor-pointer hover:tw-bg-gray-200"
          (click)="accordionItem.toggle()"
        >
            <table class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900">
                <tbody>
                    <tr>
                        <td scope="col" class="tw-pr-6 tw-font-bold">
                            {{'navbar.product.default.' + item.title | translate }}
                        </td>     
                        <td>
                            <mh-core-lib-toggle
                                [value]="item.allSelected"
                                [id]="item.id"
                                (onClick)="clickItem(item)"
                            ></mh-core-lib-toggle>
                        </td>             
                    </tr>
                </tbody>
            </table>
            <span class="tw-text-sm tw-text-gray-400" [ngClass]="{'tw-invisible': item.childrens?.length === 0 }">
                <i *ngIf="accordionItem.expanded; else accordionItemClose" class="fas fa-chevron-up tw-text-xl"></i>
                <ng-template #accordionItemClose>
                    <i class="fas fa-chevron-down tw-text-xl"></i>
                </ng-template>
            </span>
        </div>
        <div
          [style.display]="accordionItem.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-' + index"
          [attr.aria-labelledby]="'accordion-header-' + index"
        >
            <ng-container>
                <div
                    *ngFor="let subItem of item.childrens; let index = index"
                    class="tw-bg-white"
                    [style.display]="accordionItem.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + index"
                    [attr.aria-labelledby]="'accordion-header-' + index"
                >
                  <ng-container *ngIf="subItem.childrens?.length === 0">                  
                    <table class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900">
                        <tbody>
                            <tr>
                                <td scope="col" class="tw-pr-6 tw-pl-8 tw-py-5 tw-font-normal">
                                  {{'navbar.product.default.' + subItem.title | translate }}
                                </td>
                                <td>
                                  <mh-core-lib-toggle [value]="subItem.active" (onClick)="clickItem(subItem, item)"></mh-core-lib-toggle>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  </ng-container>

                  <ng-container *ngIf="subItem.childrens?.length > 0">
                    <cdk-accordion class="tw-block">
                      <cdk-accordion-item
                        #accordionItem="cdkAccordionItem"
                        class="tw-block tw-bg-white tw-mt-4"
                        role="button"
                        tabindex="0"
                        [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItem.expanded"
                        [attr.aria-controls]="'accordion-body-' + index"
                      >
                      <div
                        class="tw-flex tw-content-center tw-justify-between tw-p-4 hover:tw-cursor-pointer hover:tw-bg-gray-200"
                        (click)="accordionItem.toggle()"
                      >
                          <table class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900">
                              <tbody>
                                  <tr>
                                      <td scope="col" class="tw-pr-6 tw-pl-8 tw-font-bold">
                                        {{'navbar.product.default.' + subItem.title | translate }}
                                      </td>     
                                      <td>
                                          <mh-core-lib-toggle
                                              [id]="item.id"
                                              [ngClass]="{ 'tw-pl-4': idx === 1 }"
                                              [value]="subItem.allSelected"
                                              (onClick)="clickItem(subItem, item)"
                                          ></mh-core-lib-toggle>
                                      </td>             
                                  </tr>
                              </tbody>
                          </table>
                          <span class="tw-text-sm tw-text-gray-400" *ngIf="subItem.childrens?.length > 0">
                              <i *ngIf="accordionItem.expanded; else accordionItemClose" class="fas fa-chevron-up tw-text-xl"></i>
                              <ng-template #accordionItemClose>
                                  <i class="fas fa-chevron-down tw-text-xl"></i>
                              </ng-template>
                          </span>
                      </div>
                      <div
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        [attr.id]="'accordion-body-' + index"
                        [attr.aria-labelledby]="'accordion-header-' + index"
                      >
                          <ng-container>
                              <div
                                  *ngFor="let subSubItem of subItem.childrens; let index = index"
                                  class="tw-bg-white tw-mt-4"
                                  [style.display]="accordionItem.expanded ? '' : 'none'"
                                  [attr.id]="'accordion-body-' + index"
                                  [attr.aria-labelledby]="'accordion-header-' + index"
                              >
                                  <table class="reports-table tw-w-full tw-table-fixed tw-text-base tw-font-bold tw-text-left tw-text-gray-900 tw-pl-8">
                                      <tbody>
                                          <tr>
                                              <td scope="col" class="tw-pr-6 tw-pl-16 tw-py-5 tw-font-normal">
                                                {{'navbar.product.default.' + subSubItem.title | translate }}
                                              </td>
                                              <td>
                                                <mh-core-lib-toggle [value]="subSubItem.active" (onClick)="clickItem(subSubItem, subItem, item)"></mh-core-lib-toggle>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </ng-container>
                      </div>
                      </cdk-accordion-item>
                    </cdk-accordion>
                  </ng-container>
                </div>
            </ng-container>
        </div>
    </cdk-accordion-item>
</cdk-accordion>

<div class="tw-flex tw-justify-center tw-mt-40 tw-gap-5" *ngIf="progressRate; else saveChanges">
  <mh-core-lib-button [isSecondary]="true" [isDisabled]="false" [text]="('commons.back' | translate)" (click)="updateIndexEmit(false)"></mh-core-lib-button>
  <mh-core-lib-button [isDefault]="true" [text]="('forms.save' | translate)" (click)="updateIndexEmit(true)"></mh-core-lib-button>
</div>

<ng-template #saveChanges>
  <div class="tw-flex tw-justify-center tw-mt-10">
    <mh-core-lib-button [isDefault]="true" [text]="('commons.save_changes' | translate)" (clicked)="emitConfig()"></mh-core-lib-button>
  </div>
</ng-template>
