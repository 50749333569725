import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'mh-filter-users-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit, OnDestroy {
  formArray;
  filteredList = [];
  showAll = true;
  #list = [];
  subs$: Subscription;
  control = new FormControl('');

  @Output() onUpdateList: EventEmitter<number[]> = new EventEmitter()

  @Input() title: string;
  @Input() placeholder: string;

  @Input() type: 'chain' | 'customer' = 'chain';

  @Input() set clean(_) {
    this.showAll = true;
    this.filteredList = this.list.map((item) => ({...item, checked: false }));
    this.control.setValue('');
    this.onUpdateList.next([]);
  }

  @Input() set list(value) {
    this.#list = value;
    this.filteredList = this.list;
    const isSomeChecked = this.filteredList.some((item) => item?.checked);
    if (!isSomeChecked) {
      this.showAll = true;
    }
    this.onUpdateList.next([]);
  };

  get list () {
    return this.#list;
  }

  constructor() { }

  ngOnInit(): void {
    this.subs$ = this.control.valueChanges.pipe(
      map(value => value.trim()),
      distinctUntilChanged()
    ).subscribe((value) => {
      if (value === '' || value === null) {
        this.filteredList = this.list;
      } else {
        const searchFn = this.type === 'chain' ?
          (element, val) => element.name.toLowerCase().includes(val.toLowerCase()) :
          (element, val) => element.name.toLowerCase().includes(val.toLowerCase()) || element.id === +val;
        this.filteredList = this.list
          .filter((element) => searchFn(element, value));
      }
    });
  }

  setShowAll() {
    this.showAll = !this.showAll;
    if (this.showAll) {
      this.filteredList = this.list.map((item) => ({...item, checked: false }));
      this.onUpdateList.next([]);
    }
  }

  activate(id) {
    const elementToActivate = this.list.find((item) => item.id === id)
    elementToActivate.checked = !elementToActivate.checked;
    const isSomeActive = this.list.some((item) => item.checked);
    const elementsToGet = [];
    if (isSomeActive) {
      this.showAll = false;
      this.list.forEach((item) => {
        if (item.checked) {
          elementsToGet.push(item.id);
        }
      });
      this.onUpdateList.next(elementsToGet);
    } else {
      this.showAll = true;
      this.onUpdateList.next([]);
    }
  }
  
  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
