import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mh-team-hierarchies',
  templateUrl: './team-hierarchies.component.html',
  styles: [
  ]
})
export class TeamHierarchiesComponent {
  @Input() selectedMembers = [];
  @Input() hierarchies = [];
  @Output() updateHierarchies = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  onSelectChange() {
    this.updateHierarchies.next(this.selectedMembers);
  }

  get error() {
    if (!this.selectedMembers.some((member) => member.hierarchyId === 1)) {
      return this.translateService.get('teams.errors.hierarchies.members');
    } else if (this.selectedMembers.filter((member) => member.hierarchyId === 1).length > 1) {
      return this.translateService.get('teams.errors.hierarchies.leaders');
    } else if (this.selectedMembers.filter((member) => member.hierarchyId === 2).length > 1) {
      return this.translateService.get('teams.errors.hierarchies.supervisor');
    }
  }
}
