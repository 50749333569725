<button class="tw-text-2xs tw-text-notification-text tw-bg-gray-300 tw-rounded-full tw-w-16 tw-h-5 tw-p-[1px]" (click)="goToBack()">{{'commons.exit' | translate }}</button>
<div class="tw-flex tw-flex-col tw-mt-5 tw-rounded-xl tw-bg-white tw-px-20 tw-py-28">
    <div class="tw-flex tw-justify-between">
        <div>
            <p class="tw-text-xl tw-font-semibold" [innerHTML]="'teams.create.' + steps[index] + '-title' | translate: { name: (index === 2) ? teamName : null }"></p>
        </div>
        <div class="tw-flex tw-gap-1">
            <div class="tw-mb-5 tw-h-2 tw-rounded-full tw-bg-gray-200 tw-w-80 tw-mt-[10px]">
                <div class="tw-h-2 tw-rounded-full tw-bg-[#0F93CC] {{progress}}"></div>
            </div>
            <p class="tw-text-xl">{{this.index * 25}}%</p>
        </div>
    </div>

    <ng-container [ngSwitch]="index">
        <ng-container *ngSwitchCase="0">
            <mh-team-info [teamName]="teamName" [teamDescription]="teamDescription" (emitValue)="setInfo($event)"></mh-team-info>
        </ng-container>
        <ng-container *ngSwitchCase="1">
            <mh-team-areas [areas]="areas" [selectedAreas]="selectedAreas" (updateAreas)="setAreas($event)"></mh-team-areas>
        </ng-container>
        <ng-container *ngSwitchCase="2">
            <mh-team-members [users]="users" [selectedMembers]="selectedMembers" [hierarchies]="hierarchies" (updateMembers)="setMembers($event)"></mh-team-members>
        </ng-container>
        <ng-container *ngSwitchCase="3">
            <mh-team-hierarchies [selectedMembers]="selectedMembers" [hierarchies]="hierarchies" (updateHierarchies)="setHierarchies($event)"></mh-team-hierarchies>
        </ng-container>
    </ng-container>

    <div class="tw-flex tw-justify-center tw-mt-10 tw-gap-8">
        <mh-core-lib-button [isSecondary]="true" [isDisabled]="false" [text]="('commons.back' | translate)" *ngIf="index > 0" (clicked)="clickBack()"></mh-core-lib-button>
        <mh-core-lib-button [isDefault]="true" [text]="('user_management.create_user.next' | translate)" (clicked)="clickNext()" [isDisabled]="isButtonDisable" *ngIf="index < 3"></mh-core-lib-button>
        <mh-core-lib-button [isDefault]="true" [text]="('forms.save' | translate)" (clicked)="createTeam()" [isDisabled]="isButtonDisable" *ngIf="index === 3"></mh-core-lib-button>
    </div>
</div>
