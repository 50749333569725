import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { UtilService } from 'app/shared/util.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { concatMap } from 'rxjs/operators';
import { User } from 'app/shared/model';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';

export enum CREATE_USER_STEPS {
  searchByEmail = 1,
  createFrom = 2,
  hotelAccesses = 3,
  productAccesses = 4,
}

@Component({
  selector: 'mh-create-flow',
  templateUrl: './create-flow.component.html',
})
export class CreateFlowComponent implements OnInit, OnDestroy {
  @Select(CustomersState.currentCustomer) currentCustomerChanged$: Observable<any[]>;
  createUserSteps = CREATE_USER_STEPS;
  index: CREATE_USER_STEPS | 0 = this.createUserSteps.searchByEmail;
  customers = [];
  chains = [];
  email;
  user;
  hotelsSelected;
  userAccesses;
  progress = 0;
  progressRate = 0;
  config;
  action;
  createdUser;
  subscriptions = new Subscription();
  firstCallDone = false;
  constructor(
    private usersManagmentService: UsersManagmentService,
    private utilService: UtilService,
    private location: Location,
    private route: ActivatedRoute,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.subscriptions = this.route.queryParams.subscribe((params) => {
      this.action = params.action;
    });
    this.progressRate = 100 / (Object.keys(this.createUserSteps).length / 2);
    this.usersManagmentService.getCustomers().subscribe((result: any) => {
      if (this.utilService.currentUser.isSuperAdmin()) {
        this.customers = result;
      } else {
        const currentUserCustomerIds = this.utilService.currentUser.customers.map((customer) => customer.id);
        this.customers = result.filter((customer) => currentUserCustomerIds.includes(customer.id));
      }

      this.chains = this.customers.reduce((prev, curr) => {
        const chainToPush = curr.chain_property?.chain_id;
        if (chainToPush && !prev.find((chain) => chain.id === chainToPush.id)) {
          prev = [...prev, chainToPush];
        }
        return prev;
      }, []);
    });

    this.subscriptions.add(
      this.currentCustomerChanged$.subscribe(() => {
        if (this.firstCallDone) {
          this.goToBack();
        } else {
          this.firstCallDone = true;
        }
      }),
    );
  }

  getUserByEmail($event) {
    if (this.email && this.email !== $event) {
      this.user = null;
      this.hotelsSelected = null;
      this.config = null;
    }
    this.email = $event;
    this.index++;
  }

  findUser() {
    this.index++;
  }

  updateIndex(value) {
    if (value) {
      this.index++;
    } else {
      this.index--;
    }
  }

  emitUser(user) {
    this.user = user;
  }

  updateHotelsSelected(hotels) {
    hotels = hotels.filter(Boolean);
    this.hotelsSelected = hotels?.length > 0 ? hotels : this.hotelsSelected;
    this.usersManagmentService.getHotelAccesses(hotels.map((hotel) => hotel.id)).subscribe((result) => {
      this.userAccesses = result;
      this.config = null;
    });
  }

  goToBack() {
    this.location.back();
  }

  save(config) {
    this.config = config;

    const body = {
      email: this.user.email || '',
      username: this.user.email || '',
      first_name: this.user.name || '',
      last_name: this.user.lastName || this.user.last_name || '',
      company_position: this.user.job || '',
      flag_admin: this.user.flagAdmin || this.user.user_role.id === 1,
      language_id: this.user.language,
      mobile_number: this.user.cellphone || '',
      mobile_country_code: this.user.cellphoneCountryCode || '',
      flag_super_admin: this.action === 'internal_user',
      profile_photo_raw: this.user.profilePhotoRaw || null,
      department: this.user.department || null,
      pipedrive_id: this.user.pipedriveId || null,
      role: this.user.role || null,
      birth_date: this.user?.birthdate || null,
      user_role: this.user?.user_role || null,
    };

    this.usersManagmentService
      .createUser(body)
      .pipe(
        concatMap((user: User) => {
          this.createdUser = user;
          return this.usersManagmentService.assign_properties(
            user.id,
            this.hotelsSelected.map((hotel) => hotel.id),
          );
        }),
        concatMap(() => this.usersManagmentService.addUserAccesses(this.config, this.createdUser.id)),
      )
      .subscribe(
        (result) => {
          if (result) {
            this.index = 0;
          }
        },
        () => {
          this.alertService.handleAlert(
            'commons.not_permissions',
            5000,
            'bottom',
            'end',
            'snackbar-panel-error-user-config',
          );
        },
      );
  }

  confirm({ checkboxes, userId }) {
    ['welcomeEmail', 'invalidatePassword'].forEach((value, idx) => {
      if (checkboxes[idx]) {
        this.usersManagmentService[value](userId).subscribe();
      }
    });

    this.goToBack();
  }

  currentConfig(config) {
    this.config = config;
  }

  get currentHotelsSelecteds() {
    return (this.hotelsSelected && this.hotelsSelected.map((hotel) => hotel.id)) || [];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
