<mh-core-lib-button
  [isAction]="true"
  [icon]="'filter'"
  (click)="showFilter = !showFilter">{{ 'filters.title' | translate }}</mh-core-lib-button>

<section class="tw-w-[400px] tw-h-[350px] tw-rounded-table tw-shadow-1 tw-bg-fff tw-py-4 tw-pl-4 tw-pr-5 tw-absolute tw-top-20" [hidden]="!showFilter">
  <section class="tw-grid tw-grid-cols-2 tw-gap-4" [hidden]="chains?.length === 0">
    <article>
      <mh-filter-users-search
        [list]="chains"
        [title]="'user_management.filter.search_by_chain' | translate"
        [placeholder]="'user_management.filter.search_by_name' | translate"
        [clean]="clean"
        (onUpdateList)="onUpdateChains.next($event)"></mh-filter-users-search>
    </article>
    <article>
      <mh-filter-users-search
        [list]="customers"
        [title]="'user_management.filter.search_by_hotel' | translate"
        [placeholder]="'user_management.filter.search_by_name_id' | translate"
        [clean]="clean"
        type="customer"
        (onUpdateList)="onUpdateCustomers.next($event)"></mh-filter-users-search>
    </article>
  </section>
  <section class="tw-my-5 tw-flex tw-flex-row tw-justify-end tw-gap-2.5">
    <mh-core-lib-button [isDefault]="true" [width]="90" [text]="'shared.filters.chain.title' | translate" (click)="filter()"></mh-core-lib-button>
    <mh-core-lib-button [isSecondary]="true" [width]="90" [text]="'commons.clean' | translate" (click)="cleanFilter()"></mh-core-lib-button>
  </section>
</section>


