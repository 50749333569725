<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-end">
    <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-font-medium">
    <h3 class="tw-text-modal-title tw-text-3sm">{{ 'user_management.actions.disable_user.' + (!actionSuccess ? 'action' : 'success') | translate }}</h3>
  </div>

  <div class="tw-flex tw-justify-center tw-my-10">
    <i class="fas fa-exclamation-circle fa-3x tw-text-main-orange-warning" *ngIf="!actionSuccess"></i>
    <i class="fas fa-check-circle fa-3x tw-text-main-green-success" *ngIf="actionSuccess"></i>
  </div>

  <div class="tw-flex tw-flex-col tw-justify-center">
    <p class="tw-text-center tw-text-main-text tw-text-2sm tw-font-bold">{{ data.user?.first_name + ' ' + data.user?.last_name }}</p>
    <p class="tw-text-center tw-text-main-text tw-text-2sm tw-font-bold">{{ data.user?.company_position }}</p>
    <p class="tw-text-center tw-text-main-text tw-text-2sm tw-font-bold">{{ data.user?.email }}</p>
  </div>

  <div class="tw-flex tw-justify-around tw-mt-10" *ngIf="!actionSuccess">
    <mh-core-lib-button [isSecondary]="true" [text]="('forms.cancel' | translate)" (click)="close()"></mh-core-lib-button>
    <mh-core-lib-button [isDefault]="true" [text]="('forms.accept' | translate)" (click)="disableUser()"></mh-core-lib-button>
  </div>

  <div class="tw-flex tw-justify-center tw-mt-10" *ngIf="actionSuccess">
    <mh-core-lib-button [isDefault]="true" [text]="('forms.accept' | translate)" (click)="close(true)"></mh-core-lib-button>
  </div>
</div>
