import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';
import { TagAreaClasification } from 'app/features/surveys/models/tag-area.model';
import { SurveyService } from 'app/features/surveys/surveys.service';
import { CustomersState } from 'app/states/customers/customers.state';
import { UsersState } from 'app/states/users/users.state';
import { forkJoin } from 'rxjs';
import { TeamsService } from 'src/app/new-fs/gestor-usuarios/teams/teams.service';

@Component({
  selector: 'mh-create-teams',
  templateUrl: './create-teams.component.html',
})
export class CreateTeamsComponent implements OnInit {
  progressRate = 0;
  index = 0;
  steps = ['create', 'areas', 'members', 'hierarchies'];
  areas = [];
  users = [];
  teamName;
  teamDescription;
  selectedAreas = [];
  selectedMembers = [];
  hierarchies = [];
  checkName = false;
  normalHierarchy = 3;
  constructor(
    private store: Store,
    private surveyService: SurveyService,
    private translate: TranslateService,
    private alertService: AlertService,
    private teamsService: TeamsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const currentCustomerId = this.store.selectSnapshot(CustomersState).currentCustomer.id;
    forkJoin({
      base: this.surveyService.getBase(currentCustomerId),
      areas: this.translate.get('areas'),
      hierarchies: this.teamsService.getHierarchies(),
    }).subscribe((response: any) => {
      const base = response.base;
      const translates = response.areas;
      this.areas = this.getAreas(base.areas, translates);
      this.hierarchies = response.hierarchies;
      this.users = this.store.selectSnapshot(UsersState.currentUsers) || [];
      const normalHierarchy = this.hierarchies.find((hierarchy) => hierarchy.id === this.normalHierarchy);
      this.users = this.users.map((user) => {
        return {
          ...user,
          hierarchyId: normalHierarchy.id,
        };
      });
    });
  }

  getAreas(areas, translates) {
    return areas.map((area) => new TagAreaClasification(area.id, translates[area.id]));
  }

  goToBack() {
    this.router.navigate(['gestor-usuarios'], { queryParams: { page: 'teams' } });
  }

  clickNext() {
    if (this.index < 3) {
      this.index += 1;
    }
  }

  clickBack() {
    if (this.index > 0) {
      this.index -= 1;
    }
  }

  async setInfo(values) {
    const { teamName, teamDescription, checkName } = values;
    this.checkName = checkName;
    this.teamName = teamName || this.teamName;
    this.teamDescription = teamDescription || this.teamDescription;
  }

  setAreas(values) {
    this.selectedAreas = values;
  }

  setMembers(values) {
    this.selectedMembers = values;
  }

  setHierarchies(values) {
    this.selectedMembers = values;
  }

  createTeam() {
    const body = {
      customer_id: this.store.selectSnapshot(CustomersState).currentCustomer.id,
      name: this.teamName,
      description: this.teamDescription,
      areas: this.selectedAreas,
      leader_name: this.selectedMembers.find((user) => user.hierarchyId === 1)?.complete_name || '',
      users: this.selectedMembers.map((user) => ({
        email: user.email,
        user_id: user.id,
        name: user.complete_name,
        hierarchy: this.hierarchies.find((hierarchy) => hierarchy.id === user.hierarchyId),
      })),
    };
    this.teamsService.saveTeam(body).subscribe(() => {
      this.alertService.handleAlert(
        'teams.create.team-created',
        5000,
        'bottom',
        'end',
        'snackbar-panel-success-user-config',
      );

      this.router.navigate(['gestor-usuarios'], { queryParams: { page: 'teams' } });
    });
  }

  get progress() {
    return this.index === 0 ? `tw-w-[2%]` : `tw-w-[${this.index * 25}%]`;
  }

  get isButtonDisable() {
    switch (this.index) {
      case 0:
        return !(this.teamName?.length > 1 && this.checkName);
      case 1:
        return !(this.selectedAreas?.length > 0);
      case 2:
        return !(this.selectedMembers?.length > 1);
      case 3:
        return (
          !this.selectedMembers.some((member) => member.hierarchyId === 1) ||
          !(this.selectedMembers.filter((member) => member.hierarchyId === 1).length === 1) ||
          !(this.selectedMembers.filter((member) => member.hierarchyId === 2).length <= 1)
        );
    }
  }
}
