<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-end">
    <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-font-medium">
    <h3 class="tw-text-modal-title tw-text-3sm" *ngIf="data.propertyName?.name">{{ 'user_management.create_user.user_exists' | translate }}</h3>
  </div>

  <div class="tw-flex tw-justify-center tw-my-7">
    <div class="tw-bg-main-orange-warning tw-h-14 tw-w-14 tw-rounded-full tw-flex tw-items-center tw-justify-center">
      <i class="fas fa-user-check fa-lg tw-text-fff"></i>
    </div>
  </div>

  <div class="tw-flex tw-justify-center tw-my-4">
    <div class="tw-flex tw-flex-col tw-justify-center tw-text-center tw-text-main-text tw-text-2sm tw-h-11">
      <ng-container *ngIf="data.propertyName?.name else inactiveUser">
        <p [innerHTML]="'user_management.create_user.exists_in_property' | translate: { property: data.propertyName?.name || customer.name }"></p>
        <p class="tw-font-bold">{{ 'user_management.create_user.add_user' | translate }}</p>
      </ng-container>
      <ng-template #inactiveUser>
        <p [innerHTML]="'user_management.create_user.exists_but_not_in_property' | translate"></p>
        <p [innerHTML]="'user_management.create_user.exists_but_not_in_property_add_user' | translate: { property: customer.name }" class="tw-font-bold"></p>
      </ng-template>
    </div>
  </div>

  <div class="tw-flex tw-justify-around tw-mt-6">
    <mh-core-lib-button [isSecondary]="true" [text]="('forms.cancel' | translate)" (click)="close()"></mh-core-lib-button>
    <mh-core-lib-button [isDefault]="true" [text]="('forms.accept' | translate)" (click)="save()"></mh-core-lib-button>
  </div>

</div>
