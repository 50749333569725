<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-end">
    <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-font-medium">
    <h3 class="tw-text-modal-title tw-text-3sm">{{ 'user_management.actions.' + data?.action +'.modal_title' | translate }}</h3>
  </div>

  <div class="tw-flex tw-flex-col tw-justify-center tw-ml-10 tw-my-5">
    <ng-container *ngFor="let product of PRODUCTS; index as i">
      <ng-container *ngIf="productList[i]">
        <div class="tw-flex tw-flex-row tw-my-4 tw-justify-center">
          <div class="tw-flex tw-items-center tw-w-24">
            <p>Fidelity<span [class]="product">{{ product | titlecase }}</span></p>
          </div>
          <div class="tw-mx-10">
            <mh-core-lib-button
              [isSecondary]="true"
              [width]="200"
              [text]="('user_management.actions.alert_setup.button_label' | translate)" (click)="close(i)"></mh-core-lib-button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
