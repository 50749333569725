<p class="tw-text-notification-text-time tw-font-semibold tw-text-lg">{{ 'teams.create.members-subtitle' | translate }}</p>
<div class="tw-flex tw-justify-between tw-content-center">
    <div class="tw-mt-20 tw-w-96">
        <p class="tw-text-sm tw-font-semibold">{{ 'teams.create.members-label' | translate | uppercase }} {{'(' + selectedMembers?.length + ')'}}</p>
        <mh-core-lib-tag-input
            [options]="users"
            [selectedOptions]="selectedMembers"
            [displayChipsFn]="displayChipsMembersFn"
            [displayChipsListFn]="displayChipsMembersFn"
            [allSelected]="false"
            [checkedAllOption]="false"
            [showAutocompleteInput]="true"
            [displayCheckInput]="false"
            [boxOpen]="false"
            [showCloseBtnFn]="isNotLeader"
            [inputTagFilter]="inputTagMembersFilter"
            [hasAutocompleteFilter]="true"
            (optionClicked)="handleMembersInput($event)"
        ></mh-core-lib-tag-input>
    </div>
    <div class="tw-h-64">
        <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/teams/members.png" alt="members image"/>
    </div>
</div>
