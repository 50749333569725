<ng-container *ngIf="isGlobal; else commonManager">
  <ng-template [ngTemplateOutlet]="userManager"></ng-template>
</ng-container>

<ng-template #commonManager>
  <mh-box-element>
    <mh-wait-table *ngIf="waiting" [isHeavyQuery]="isHeavyQuery" [numberColumns]="5"></mh-wait-table>
      <mh-core-lib-tabs [className]="'!tw-px-10 !tw-py-3'" [selectedTab]="selectedTab" *ngIf="!waiting" >
        <mh-core-lib-tab [title]="'configuration.users.manager.title' | translate">
          <div class="tw-rounded-xl tw-bg-white">
            <ng-template [ngTemplateOutlet]="userManager"></ng-template>
          </div>
        </mh-core-lib-tab>
        <mh-core-lib-tab [title]="'configuration.users.entries_log.entries' | translate">
          <div class="tw-rounded-xl tw-bg-white">
            <mh-entries-log></mh-entries-log>
          </div>
        </mh-core-lib-tab>
        <mh-core-lib-tab [title]="'teams.title' | translate">
          <div class="tw-rounded-xl tw-bg-white">
            <mh-teams-table [startDateLast30d]="startDateLast30d" [endDateLast30d]="endDateLast30d"></mh-teams-table>
          </div>
        </mh-core-lib-tab>
      </mh-core-lib-tabs>
  </mh-box-element>
</ng-template>

<ng-template #userManager>
  <mh-core-lib-content>
    <ng-container head>
      <mh-core-lib-header-content>
        <section class="tw-flex tw-justify-between">
          <article class="tw-flex tw-space-x-4">
            <article [hidden]="waiting" *ngIf="isSuperAdmin && this.filterStatus !== 'internal'">
              <mh-filter-users
                [chains]="chains"
                [customers]="customersFiltered"
                (onUpdateChains)="updateChainsToGet($event)"
                (onUpdateCustomers)="updateCustomersToGet($event)"
                (onFilter)="filter()"></mh-filter-users>
            </article>
            <article [hidden]="waiting">
              <mh-core-lib-selector-users
                [activeUsers]="activeUsers"
                [inactiveUsers]="inactiveUsers"
                [internalUsers]="internalUsers"
                [isSuperAdmin]="isSuperAdmin"
                [currentStatus]="filterStatus"
                (filterUpdated)="filterUsers($event)"
                ></mh-core-lib-selector-users>
            </article>
            <article [hidden]="selectedUsers?.length <= 1 || waiting">
              <mh-actions [actions]="actions" [smallButton]="false" (actionClicked)="actionClicket($event)"></mh-actions>
            </article>
          </article>
          <article class="tw-flex tw-space-x-4" [hidden]="waiting">
            <article>
              <mh-core-lib-searcher
                [placeholder]="'filters.search_user' | translate"
                [formControl]="search"></mh-core-lib-searcher>
            </article>
            <article>
              <mh-core-lib-actions-wrapper
                  slug="user_managment_actions"
                >
              <mh-create-user [customers]="customers" [chains]="chains" [isGlobal]="isGlobal" (updateCustomers)="updateUsers()"></mh-create-user>
              </mh-core-lib-actions-wrapper>
            </article>
            <article>
              <mh-core-lib-report-button [startDate]="startDateLast30d" [finishDate]="endDateLast30d" [isFromUserEntries]="true" [activeEmail]="false" *ngIf="!isGlobal"></mh-core-lib-report-button>
            </article>
          </article>
        </section>
      </mh-core-lib-header-content>
    </ng-container>

    <ng-container main-content>
      <mh-core-lib-main-content>
        <div class="tw-flex tw-flex-row tw-justify-center tw-w-full tw-p-7" [hidden]="!waiting">
          <mh-spinner-load></mh-spinner-load>
        </div>

        <mh-core-lib-table [DefaultPaginationSize]="paginationSize" [totalElements]="totalElements" (paginationSize)="paginationSizeChange($event)" (pageChanged)="pageChange($event)" [currentPage]="currentPage" *ngIf="!waiting" [showFooter]="true" >
          <ng-container thead>
            <ng-container *ngTemplateOutlet="head"></ng-container>
          </ng-container>
          <ng-container tbody>
            <ng-container *ngTemplateOutlet="body"></ng-container>
          </ng-container>
        </mh-core-lib-table>

      </mh-core-lib-main-content>
    </ng-container>

  </mh-core-lib-content>


  <ng-template #head>
    <tr>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            <input type="checkbox" class="tw-border-2 tw-rounded-sm" (change)="selectAllUsers($event)" *ngIf="isSuperAdmin || isAdmin"/>
          </div>
          <div>
            <span>{{ 'commons.user' | translate | titlecase }}</span>
          </div>
          <div>
            <button (click)="sort(0)">
              <i class="fas {{sortState[0]}}"></i>
            </button>
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'user_management.assigned_properties' | translate }}
          </div>
          <div>
            <button (click)="sort(1)">
              <i class="fas {{sortState[1]}}"></i>
            </button>
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'commons.chain' | translate }}
          </div>
          <div>
            <button (click)="sort(2)">
              <i class="fas {{sortState[2]}}"></i>
            </button>
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'user_management.create_user.personal_info.role' | translate }}
          </div>
          <i class="fas fa-question-circle tw-cursor-pointer tw-mt-[3px]" (click)="openRolesAndActionsModal()"></i>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'commons.status' | translate }}
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            {{ 'commons.created_at' | translate }}
          </div>
          <div>
            <button (click)="sort(4)">
              <i class="fas {{sortState[4]}}"></i>
            </button>
          </div>
        </div>
      </th>
      <th>
        <div class="tw-flex tw-space-x-2">
          <div>
            <div class="tw-flex tw-space-x-2">
              <div>
                {{ 'user_management.last_login' | translate }}
              </div>
              <div>
                <button (click)="sort(5)">
                  <i class="fas {{sortState[5]}}"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </th>
      <th>
        &nbsp;
      </th>
    </tr>
  </ng-template>

  <ng-template #body [formGroup]="form">
    <ng-container formArrayName="users">
      <ng-container *ngFor="let user of users; let i=index; trackBy: trackByFn">
        <tr>
          <td class="tw-py-2">
            <article class="tw-flex tw-space-x-3">
              <div class="tw-self-center">
                <input type="checkbox" class="tw-border-2 tw-rounded-sm" [id]="i" [value]="i" [formControlName]="i" *ngIf="isSuperAdmin || isAdmin"/>
              </div>
              <div class="tw-flex tw-self-center tw-flex-col tw-space-y-1">
                <span class="tw-font-bold">{{user.first_name}} {{user.last_name}}</span>
                <span>{{user.company_position}}</span>
                <span>{{user.email}}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center">
                <span class="tw-font-medium">{{user.customers_ids?.length || 0}}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center">
                <span class="tw-font-medium">{{user.chains_ids?.length || 0}}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center" *ngIf="user?.user_role?.id">
                <select class="tw-rounded-md tw-w-44 tw-h-9" [(ngModel)]="user.user_role.id" [ngModelOptions]="{standalone: true}" (change)="setRole($event, user)">
                  <option *ngFor="let role of roles; trackBy: trackByFn" [value]="role.id">{{ 'models.user_profile.roles.' + (role.id) | translate }}</option>
                </select>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center">
                <div
                  class="tw-rounded-full tw-w-5 tw-h-5"
                  [ngClass]="{
                    'tw-bg-green-500': !user.flag_is_locked,
                    'tw-bg-red-500': user.flag_is_locked,
                  }"
                  [mhTooltip]="('user_management.' + (user.flag_is_locked ? 'blocked' : 'active')) | translate"
                  position="above"
                ></div>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center">
                <span class="tw-font-medium">{{user.created_at | date: 'd MMM y'}}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <article class="tw-flex">
              <div class="tw-self-center">
                <span class="tw-font-medium">{{ user?.last_login ? (user.last_login | date: 'd MMM y HH:mm') : '-'}}</span>
              </div>
            </article>
          </td>
          <td class="tw-py-2">
            <mh-core-lib-actions-wrapper slug="user_managment_actions">
              <mh-actions [actions]="individualActions" [user]="user" (actionClicked)="actionClicket($event, true)"></mh-actions>
            </mh-core-lib-actions-wrapper>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-template>
