import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-existing-user-modal',
  templateUrl: './existing-user-modal.component.html',
  styleUrls: ['./existing-user-modal.component.scss']
})
export class ExistingUserModalComponent extends CoreLibModalComponent implements OnInit {

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private utilService: UtilService
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
  }

  save() {
    this.close(this.customer.id);
  }

  get customer() {
    return this.utilService.currentHotel;
  }
}
