<div class="tw-flex-col tw-flex-wrap tw-mt-14">
    <div class="tw-flex tw-justify-center tw-gap-x-12">
        <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/teams/24hourcutomerservice.png"/>
        <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/teams/Bellboy.png"/>
        <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/teams/breakfast.png"/>
        <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/teams/housekeeping.png"/>
    </div>
    <div class="tw-flex tw-justify-center tw-gap-x-12 tw-mt-10">
        <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/teams/laundry.png"/>
        <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/teams/receptionist.png"/>
        <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/teams/Swimmingpool.png"/>
        <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/teams/Waiter.png"/>
    </div>
</div>