<article
  class="tw-flex tw-flex-col tw-relative tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden tw-max-w-xl tw-h-full tw-mx-auto">
  <!-- Close button -->
  <i class="far fa-times tw-cursor-pointer tw-absolute tw-right-4 tw-top-3 tw-text-gray-500" (click)="close()"></i>

  <!-- Title -->
  <section class="tw-py-4 tw-text-center tw-rounded-t-lg">
    <h1 class="tw-text-2xl tw-font-semibold tw-text-gray-800">{{ 'followup.summary.emails-pms.title' | translate }}</h1>
  </section>

  <!-- Description -->
  <section class="tw-px-8 tw-py-4 tw-text-center tw-text-gray-600 tw-text-lg tw-w-full">
    <p class="tw-text-center">
      <span class="tw-font-bold">{{ data.hotelName }}</span> {{ 'followup.summary.emails-pms.modal.has_a' | translate }}
      <span class="tw-font-bold" [ngClass]="{
      'tw-text-[#2BBB39]': ['good', 'very-good'].includes(status),
      'tw-text-[#F07E28]': ['regular'].includes(status),
      'tw-text-[#FF2020]': ['bad', 'very-bad'].includes(status),
    }">{{ data.pms.coverage | formatPercent: true:false:true }}</span> {{
      'followup.summary.emails-pms.modal.valid_guests' | translate }}
      <span class="tw-font-bold">{{ currentDateFilter | translate }}</span>.
    </p>
  </section>

  <!-- Table -->
  <section class="tw-px-6 tw-py-4">
    <table class="tw-w-full tw-text-left tw-border-collapse">
      <thead>
        <tr>
          <th class="tw-py-2 tw-border-b tw-font-medium tw-text-gray-700">{{ currentDateFilter | translate }}</th>
          <th class="tw-py-2 tw-border-b tw-font-medium tw-text-gray-700 tw-text-right">{{
            'followup.summary.emails-pms.modal.actual' | translate }}</th>
          <th class="tw-py-2 tw-border-b tw-font-medium tw-text-gray-700 tw-text-right">{{
            'followup.summary.emails-pms.modal.valid' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="tw-py-2 tw-text-gray-800">{{ 'followup.summary.emails-pms.modal.total_guests' | translate }}</td>
          <td class="tw-py-2 tw-text-right tw-text-gray-800"> {{ data.pms.guestsCount }} </td>
          <td class="tw-py-2 tw-text-right tw-text-gray-800">-</td>
        </tr>
        <tr>
          <td class="tw-py-2 tw-text-gray-800">{{ 'followup.summary.emails-pms.modal.with_email' | translate }}</td>
          <td class="tw-py-2 tw-text-right tw-text-gray-800"> {{ data.pms.emailsCount }} </td>
          <td class="tw-py-2 tw-text-right">
            {{ data.pms.emailsValid }}
            <span class="tw-font-semibold" [ngClass]="{
                'tw-text-[#2BBB39]': ['good', 'very_good'].includes(coverageEmailStatus),
                'tw-text-[#F07E28]': ['regular'].includes(coverageEmailStatus),
                'tw-text-[#FF2020]': ['bad', 'very_bad'].includes(coverageEmailStatus),
              }">
              ({{ data.pms.coverageEmail | formatPercent: true:false:true }})
            </span>
          </td>
        </tr>
        <tr>
          <td class="tw-py-2 tw-text-gray-800">{{ 'followup.summary.emails-pms.modal.with_mobile_phone' | translate }}
          </td>
          <td class="tw-py-2 tw-text-right tw-text-gray-800"> {{ data.pms.phonesCount }} </td>
          <td class="tw-py-2 tw-text-right">
            {{ data.pms.phonesValid }}
            <span class="tw-font-semibold" [ngClass]="{
                'tw-text-[#2BBB39]': ['good', 'very_good'].includes(coveragePhoneStatus),
                'tw-text-[#F07E28]': ['regular'].includes(coveragePhoneStatus),
                'tw-text-[#FF2020]': ['bad', 'very_bad'].includes(coveragePhoneStatus),
              }">
              ({{ data.pms.coveragePhone | formatPercent: true:false:true }})
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</article>
