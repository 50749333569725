<p class="tw-text-notification-text-time tw-font-semibold tw-text-lg">{{ 'teams.create.hierarchies-subtitle' | translate }}</p>
<div class="tw-flex tw-justify-between tw-content-center">
    <div class="tw-mt-20 tw-w-96">
        <p class="tw-text-sm tw-font-semibold">{{ 'teams.create.members-label' | translate | uppercase }} {{'(' + selectedMembers?.length + ')'}}</p>
        <ng-container *ngFor="let member of selectedMembers">
            <div class="tw-flex tw-my-2">
                <div class="tw-w-96">
                    <p>{{ member?.complete_name || member?.name }}</p>
                </div>
                <select class="tw-ml-14 tw-h-[34px] tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font tw-pt-1" [(ngModel)]="member.hierarchyId" (change)="onSelectChange()">
                    <option *ngFor="let hierarchy of hierarchies" [ngValue]="hierarchy.id">{{ 'teams.hierarchies.' + hierarchy.id | translate }}</option>
                </select>
            </div>
        </ng-container>
        <p class="tw-mt-5 tw-text-notification-bg-error tw-font-bold">
            {{ error | async }}
        </p>
    </div>
    <div class="tw-h-64">
        <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/teams/hierarchies.png" alt="hierarchies image"/>
    </div>
</div>