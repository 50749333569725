<div class="tw-flex tw-flex-col">
    <div class="tw-flex tw-justify-end">
        <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
    </div>
  
    <ng-container *ngIf="!loading">
      <div class="tw-flex tw-justify-center tw-font-medium">
          <h3 class="tw-text-modal-title tw-text-3sm">{{ 'user_management.actions.manage_role.modal_title' | translate: { users } }}</h3>
      </div>
      
      <div class="tw-flex tw-justify-center tw-flex-wrap tw-my-40 tw-gap-3">
          <div class="tw-flex tw-items-center tw-justify-center tw-w-[116px] tw-h-[30px] tw-rounded-md tw-gap-10 tw-bg-[#F4F4F4]" [ngClass]="{'tw-bg-answered': role.status, 'tw-bg-[#F4F4F4]': !role.status }" *ngFor="let role of roles; trackBy: trackByRole;" (click)="setRole(role.id)">
              <p class="tw-text-center tw-cursor-pointer tw-font-semibold" [ngClass]="{'tw-text-button-primary-default-text': role.status, 'tw-text-font-gray': !role.status }">{{ 'models.user_profile.roles.' + role.id | translate }}</p>
          </div>
      </div>
  
      <div class="tw-flex tw-justify-center tw-gap-5">
          <mh-core-lib-button [isSecondary]="true" [isDisabled]="false" [text]="('commons.cancel' | translate)" (click)="close()"></mh-core-lib-button>
          <mh-core-lib-button [isDefault]="true"  [text]="('commons.confirm' | translate)" (click)="saveRole()"></mh-core-lib-button>
      </div>
    </ng-container>

    <div *ngIf="loading" class="tw-bg-white tw-z-50">
      <mh-core-lib-loader-wrapper 
        [type]="'spinner'"
        [loading]="loading"
        className="tw-self-center" 
        spinnerClassName="tw-text-[50px]">
      </mh-core-lib-loader-wrapper>
    </div>
</div>
  