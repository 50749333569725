import { Component, OnInit } from '@angular/core';
import { TeamsService } from '../teams.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Team } from '../model/teams.model';
import { Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { SurveyService } from 'app/features/surveys/surveys.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { UsersState } from 'app/states/users/users.state';
import { TagAreaClasification } from 'app/features/surveys/models/tag-area.model';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';

@Component({
  selector: 'mh-config-team',
  templateUrl: './config-team.component.html',
})
export class ConfigTeamComponent implements OnInit {
  selectedTab = 0;
  teamId;
  team: Team;
  areas;
  hierarchies;
  users;
  selectedUsers;
  currentName;
  checkName = false;
  checkAreas = false;
  checkHierarchies = false;
  loading = false;

  constructor(
    private teamsService: TeamsService,
    private route: ActivatedRoute,
    private store: Store,
    private surveyService: SurveyService,
    private translate: TranslateService,
    private alertService: AlertService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.teamId = this.route.snapshot.queryParams.teamId;
    this.team = this.teamsService.teams.find((team) => team.id === +this.teamId);
    this.currentName = this.team.name;

    const currentCustomerId = this.store.selectSnapshot(CustomersState).currentCustomer.id;
    forkJoin({
      base: this.surveyService.getBase(currentCustomerId),
      areas: this.translate.get('areas'),
      hierarchies: this.teamsService.getHierarchies(),
    }).subscribe((response: any) => {
      const base = response.base;
      const translates = response.areas;
      this.areas = this.getAreas(base.areas, translates);
      this.hierarchies = response.hierarchies;
      this.users = this.store.selectSnapshot(UsersState.currentUsers) || [];
      const teamUsers = this.team.users.map((user) => user.user_id);
      this.selectedUsers = this.users
        .filter((user) => teamUsers.includes(user.id))
        .map((user) => {
          const teamUserFound = this.team.users.find((teamUser) => teamUser.user_id === user.id);
          return {
            ...user,
            hierarchyId: teamUserFound.hierarchy.id,
            hierarchy: teamUserFound.hierarchy,
          };
        });
    });
  }
  setInfo(values) {
    const { teamName, teamDescription, checkName } = values;
    this.checkName = checkName;
    this.team.name = teamName || this.team.name;
    this.team.description = teamDescription || this.team.description;
  }

  getAreas(areas, translates) {
    return areas.map((area) => new TagAreaClasification(area.id, translates[area.id]));
  }

  setAreas(areas) {
    this.checkAreas = true;
    this.team.areas = areas;
  }

  setHierarchies(users) {
    this.team.users = users.map((user) => ({
      email: user.email,
      user_id: user.id,
      name: user.complete_name,
      hierarchy: user.hierarchyId
        ? this.hierarchies.find((hierarchy) => hierarchy.id === user.hierarchyId)
        : this.hierarchies.find((hierarchy) => hierarchy.id === 3),
    }));
    this.checkHierarchies = true;
  }

  setMembers(users) {
    this.team.users = users.map((user) => ({
      email: user.email,
      user_id: user.id,
      name: user.complete_name,
      hierarchy: user.hierarchyId
        ? this.hierarchies.find((hierarchy) => hierarchy.id === user.hierarchyId)
        : this.hierarchies.find((hierarchy) => hierarchy.id === 3),
    }));
    this.checkHierarchies = true;
  }

  saveData() {
    this.loading = true;
    const body = {
      id: this.team.id,
      name: this.team.name,
      description: this.team.description,
    };

    this.teamsService.editData(body).subscribe(() => {
      this.init();
      this.loading = false;
      this.alertService.handleAlert(
        'user_management.actions.success',
        5000,
        'bottom',
        'end',
        'snackbar-panel-success-user-config',
      );
    });
  }

  saveUsers() {
    this.loading = true;
    const body = {
      id: this.team.id,
      dto: this.team.users,
    };

    this.teamsService.editUsers(body).subscribe(() => {
      this.checkHierarchies = false;
      this.init();
      this.loading = false;
      this.alertService.handleAlert(
        'user_management.actions.success',
        5000,
        'bottom',
        'end',
        'snackbar-panel-success-user-config',
      );
    });
  }

  saveAreas() {
    const body = {
      id: this.team.id,
      dto: this.team.areas,
    };

    this.teamsService.editAreas(body).subscribe(() => {
      this.checkAreas = false;
      this.init();
      this.alertService.handleAlert(
        'user_management.actions.success',
        5000,
        'bottom',
        'end',
        'snackbar-panel-success-user-config',
      );
    });
  }

  get hierarchiesValidation() {
    return (
      !this.team.users.some((member) => member.hierarchy.id === 1) ||
      !(this.team.users.filter((member) => member.hierarchy.id === 1).length === 1) ||
      !(this.team.users.filter((member) => member.hierarchy.id === 2).length <= 1)
    );
  }

  goToBack() {
    this.router.navigate(['gestor-usuarios'], { queryParams: { page: 'teams' } });
  }
}
