import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {Router} from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import {NavbarService} from 'app/_services/navbar.service';
import { MenusState } from 'app/states/menus/menus.state';
import { Observable, Subscription } from 'rxjs';
import { UtilService } from '../util.service';

@Component({
  selector: 'mh-new-menu',
  templateUrl: './new-menu.component.html',
  styleUrls: ['./new-menu.component.scss']
})
export class NewMenuComponent implements OnInit, OnDestroy {

  @Select(MenusState.currentUserMenu) menuState$: Observable<any>;

  @Input()
  currentUser;

  @Input()
  customer;

  @Input()
  isMobile = false;

  @Input()
  isRetail = false;

  @Output()
  gaEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  closeMenu: EventEmitter<any> = new EventEmitter<any>();

  subscriptions: Subscription = new Subscription();
  

  allowedMenus = [];
  menuState;

  tutorialLinks = {
    es: 'https://soporte.myhotel.cl/es/base-conocimientos',
    en: 'https://soporte.myhotel.cl/en/base-conocimientos',
    pt: 'https://soporte.myhotel.cl/pt-br/base-conocimientos',
  };
  readonly version: any;

  constructor(
    private router: Router,
    private navbarService: NavbarService,
    private meta: Meta,
    private store: Store,
    private utilService: UtilService,
  ) {
    this.version = this.utilService.getAppVersion();
   }

  ngOnInit() {
    this.subscriptions.add(
      this.menuState$.subscribe((menu) => {
        this.menuState = menu.reduce((acc, curr) => {
          const item = {
            [curr.slug]: curr,
          }
          return { ...acc, ...item };
        }, {});
        this.allowedMenus = menu.map((item) => item.slug);
      })
    )
  }

  navigateToProfile(config=null) {
    if (config) {
      this.router.navigate(['perfil'], {
        queryParams: { page: 'configuration', users: this.utilService.currentUser.id, isGlobal: false, tab: 'alerts', userEmail: this.utilService.currentUser.email },
      });
    } else {
      this.emitGa('option profile');
      this.router.navigate(['perfil'], {
        queryParams: { page: 'configuration', users: this.utilService.currentUser.id, isGlobal: false, tab: 'personal_adjusts', userEmail: this.utilService.currentUser.email },
      });
    }
  }

  emitGa(value) {
    this.gaEvent.emit(value);
  }

  emitCloseMenu() {
    this.closeMenu.emit();
  }

  setStatusNavbar(status) {
    this.navbarService.setStatus(status);
  }

  toggleDesktopMobile() {
    const viewportTag = {
      name: 'viewport',
      content: 'width=device-width, initial-scale=0.1'
    };
    this.meta.updateTag(viewportTag, `name="viewport"`);
  }

  goToIntegrations() {
    this.router.navigate(['online', 'integraciones']);
  }

  get isAdmin() {
    return this.currentUser.admin;
  }

  get isSuperAdmin() {
    return this.currentUser.superAdmin;
  }

  get userName() {
    const name = this.currentUser.name;
    const lastname = this.currentUser.lastName;
    return `${name} ${lastname}`;
  }

  get customerName() {
    return this.customer.name;
  }

  get currentLanguageCode() {
    return this.store.selectSnapshot(DefaultLanguageState.languageCode);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
