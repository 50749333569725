import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { CustomValidators } from 'app/shared/custom-validators';
import { UtilService } from 'app/shared/util.service';
import { ExistingUserModalComponent } from '../existing-user-modal/existing-user-modal.component';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'mh-search-by-email-modal',
  templateUrl: './search-by-email-modal.component.html',
  styleUrls: ['./search-by-email-modal.component.scss'],
})
export class SearchByEmailModalComponent implements OnInit {
  emailControl = new FormControl('', [Validators.required, CustomValidators.email]);

  @Input() progressRate;
  @Input() email;
  @Input() dataCustomers;

  @Output()
  emitEmail = new EventEmitter();

  constructor(
    private usersManagmentService: UsersManagmentService,
    private utilService: UtilService,
    private modalService: ModalService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    if (this.email) { 
      this.emailControl.setValue(this.email);
    }
  }

  findUser() {
    if (!this.emailControl?.errors) {
      this.usersManagmentService.findUser(this.emailControl.value).subscribe(
        (user: any) => {
          if (user) {
            if (user.customers_ids?.includes(this.utilService.currentHotel?.id) && !user?.flag_deleted) {
              this.emailControl.setErrors({ existingUserInProperty: true });
            } else {
              const propertyName = user.customers_ids?.length && !user?.flag_deleted
                ? this.dataCustomers.find((customer) => customer.id === user.customers_ids[0])
                : null;
              const existingUserModal = this.modalService.open(
                ExistingUserModalComponent,
                { data: { user, propertyName } },
                ['overlay-panel'],
              );

              existingUserModal
                .afterClosed()
                .pipe(take(1))
                .subscribe((hotelId) => {
                  if (hotelId) {
                    this.usersManagmentService.assign_properties(user.id, hotelId).subscribe((result) => {
                      if (result) {
                        if (user?.flag_deleted) {
                          this.usersManagmentService.activate_user(user.id).subscribe((reactivatedUser) => {
                            if (reactivatedUser) {
                              this.emitEmail.next(reactivatedUser);
                            }
                          });
                        }
                        this.location.back();
                      }
                    });
                  }
                });
            }
          } else {
            this.emitEmail.next(this.emailControl.value);
          }
        },
        () => this.emitEmail.next(this.emailControl.value),
      );
    }
  }
}
