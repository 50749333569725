<p class="tw-text-notification-text-time tw-font-semibold tw-text-lg">{{ 'teams.create.areas-subtitle' | translate }}</p>
<div class="tw-flex tw-justify-between tw-content-center">
    <div class="tw-mt-20 tw-w-96">
        <p class="tw-text-sm tw-font-semibold">{{ 'teams.create.areas-label' | translate | uppercase }}</p>
        <mh-core-lib-tag-input
            [options]="areas"
            [selectedOptions]="selectedAreas"
            [displayChipsFn]="displayChipsFn"
            [displayChipsListFn]="displayChipsFn"
            [allSelected]="false"
            [checkedAllOption]="false"
            [showAutocompleteInput]="true"
            [displayCheckInput]="false"
            [boxOpen]="false"
            [inputTagFilter]="inputTagFilter"
            [hasAutocompleteFilter]="true"
            (optionClicked)="handleAreasInput($event)"
        ></mh-core-lib-tag-input>
    </div>
    <mh-areas-images></mh-areas-images>
</div>