import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { CorecLibSuccessModalComponent } from 'app/core-lib/dialogs/success-modal/success-modal.component';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { take } from 'rxjs/operators';
import { ExistingUserModalComponent } from './existing-user-modal/existing-user-modal.component';
import { SearchByEmailModalComponent } from './search-by-email-modal/search-by-email-modal.component';
import { CreateUserMainModalComponent } from './create-user-main-modal/create-user-main-modal.component';
import { UtilService } from 'app/shared/util.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'mh-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  showMenu = false;
  actions = ['customer_user', 'internal_user'];
  isSuperAdmin = false;
  isAdmin = false;
  @Input() customers;
  @Input() chains;
  @Input() isGlobal = false;
  @Output() updateCustomers = new EventEmitter();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showMenu = false;
    }
  }

  constructor(
    private eRef: ElementRef,
    private modalService: ModalService,
    private userManagementService: UsersManagmentService,
    private utilService: UtilService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const currentUser = this.utilService.getCurrentUser();
    this.isSuperAdmin = currentUser.isSuperAdmin() && currentUser.role === 'full-admin' && this.isGlobal;
    this.isAdmin = currentUser.isAdmin();
    if (!this.isSuperAdmin) {
      this.actions = [this.actions[0]];
    }
  }

  clickAction(action) {
    this.router.navigate(['create-user'], { relativeTo: this.route, queryParams: { action } });
  }
}
