import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreLibGenericConfirmationModalComponent } from '../../generic-confirmation-modal/generic-confirmation-modal.component';
import { ModalService } from '../../modal/modal.service';
import { CasesService } from 'app/shared/cases/services/cases.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';
import { TeamsService } from 'app/new-fs/gestor-usuarios/teams/teams.service';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-core-lib-case-detail-form',
  templateUrl: './case-detail-form.component.html',
  styleUrls: ['./case-detail-form.component.scss'],
})
export class CoreLibCaseDetailFormComponent implements OnInit, OnDestroy {
  @Input()
  currentUser;

  @Input()
  displayFnTypes;

  @Input()
  types;

  @Input()
  displayFnPriorities;

  @Input()
  priorities;

  @Input()
  displayChipsFn;

  @Input()
  areas;

  @Input()
  states;

  @Input()
  users;

  @Input()
  caseData;

  @Input()
  subscribers;

  @Input()
  isSubscriber;

  @Input()
  canEdit = false;

  @Input()
  customerId;

  @Input()
  skipValidation = false;

  @Input()
  message = '';

  @Output()
  caseUpdated = new EventEmitter();

  selectedAreas = [];
  caseForm: FormGroup;
  usersFromTeam = [];
  savingAreas = false;

  displayUsersOptions = (option) => {
    return option?.name || `${option.first_name} ${option.last_name}`;
  };

  autocompleteFilterFn = (options, value) => {
    return options.filter((option: any) => {
      return (
        option?.name?.toLowerCase().includes(value.toLowerCase()) ||
        option?.first_name.toLowerCase().includes(value.toLowerCase()) ||
        `${option?.first_name.toLowerCase()} ${option?.last_name.toLowerCase()}`.includes(value.toLowerCase())
      );
    });
  };

  inputTagFilter = (options, value) => {
    return options.filter((option: any) => {
      return (
        option?.clasification_text?.toLowerCase().includes(value) ||
        option?.clasification_text_en?.toLowerCase().includes(value) ||
        option?.clasification_text_pt?.toLowerCase().includes(value)
      );
    });
  };
  showCloseBtnFnSubscribers = (option) => {
    return option?.id === this.currentUser?.id;
  };

  subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private casesService: CasesService,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private alertService: AlertService,
    private teamsService: TeamsService,
    private utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.setAssignedUsers();
    this.setForm();
    this.selectedAreas = this.caseData.related_areas.map((area) => {
      const ptTranslation =
        this.areas.find((areaRaw) => areaRaw.id === area.area_entity.id)?.clasification_text_pt || null;
      return {
        ...area.area_entity,
        clasification_text_pt: ptTranslation,
      };
    });
    
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async setAssignedUsers() {
    if (!this.caseData.team_details) return;
    try {
      const res = await this.teamsService.getTeamsByCustomerId(this.customerId).toPromise();
      if (res) {
        const findedTeam = res.content.find((team) => team.id === this.caseData.team_details.id);
        this.usersFromTeam = findedTeam.users;
      }
    } catch (error) {
      console.error(error);
    }
  }

  setForm() {
    this.caseForm = this.fb.group({
      title: [this.caseData.title, [Validators.required, Validators.maxLength(500)]],
      description: [this.caseData.description, [Validators.required, Validators.maxLength(3000)]],
      state: [this.caseData.state, [Validators.required]],
      priority: [this.caseData.priority, [Validators.required]],
      case_type: [this.caseData.case_type, [Validators.required]],
      author: [this.caseData.author],
      assigned: [this.caseData.assigned, [Validators.required]],
      related_areas: [
        this.caseData.related_areas.map((area) => ({ area_entity: area.area_entity })),
        [Validators.required],
      ],
    });
  }

  handleTypeChange() {
    const config = {
      data: {
        text: this.translateService.instant('cases.messages.type_change_confirm'),
      },
    };
    const backdropClasses = ['overlay-backdrop-inherit'];
    const ref = this.modalService.open(
      CoreLibGenericConfirmationModalComponent,
      config,
      'overlay-panel',
      backdropClasses,
    );
    this.subscriptions.add(
      ref.afterClosed().subscribe((response) => {
        if (response === 'ACCEPT') {
          this.handleEditCase();
        }
      }),
    );
  }

  async handleAreasInput(options) {
    this.savingAreas = true;
    const areas = options.reduce((prev, curr) => {
      return [
        ...prev,
        {
          area_entity: curr,
        },
      ];
    }, []);
    this.caseForm.get('related_areas').setValue(areas);

    try {
      const res = await this.casesService.updateAreas(areas, this.caseData.id).toPromise();
      if (res) {
        this.showNotification();
        this.caseUpdated.emit(this.caseData);
        this.savingAreas = false;
        this.utilService.ga('cases', 'desk-click-edit-areas');
      }
    } catch (error) {
      console.error(error);
      this.caseData.related_areas = { ...this.caseData.related_areas };
      this.savingAreas = false;
    }
  }

  handleSubscribersChange(options) {
    let ids;

    if (this.subscribers) {
      const isCurrentUserPresentSubscribers = this.subscribers.find(
        (subscriber) => subscriber.id === this.currentUser.id,
      );
      if (isCurrentUserPresentSubscribers) {
        const isCurrentUserPresentNewOptions = options.find(
          (option) => option.id === isCurrentUserPresentSubscribers.id,
        );
        if (!isCurrentUserPresentNewOptions) {
          this.subscriptions.add(
            this.casesService.unsubscribe(this.caseData.id).subscribe(() => {
              this.showNotification();
              this.subscribers = options;
              this.caseUpdated.emit(this.caseData);
              this.utilService.ga('cases', 'desk-click-edit-subscribers');
            }),
          );
          return;
        }
      }
    }

    if (!this.subscribers) {
      ids = options.map((option) => option.id);
    } else {
      ids = options
        .filter((option) => !this.subscribers.find((sub) => sub.id === option.id))
        .map((option) => option.id);
    }
    this.subscriptions.add(
      this.casesService.updateSubscribers(this.caseData, ids).subscribe(() => {
        this.showNotification();
        this.subscribers = options;
        this.caseUpdated.emit(this.caseData);
        this.utilService.ga('cases', 'desk-click-edit-subscribers');
      }),
    );
  }

  async handleAssignOption(option) {
    const optionIdSelected = this.isOfTeam ? option.user_id : option.id;
    if (this.caseData.assigned.id === optionIdSelected) return;

    try {
      const res = await this.casesService.updateAssigned(this.caseData.id, optionIdSelected).toPromise();
      if (res) {
        this.caseForm.get('assigned').setValue(option);
        this.showNotification();
        this.caseUpdated.emit(this.caseData);
        this.utilService.ga('cases', 'desk-click-edit-assigned', 'assigned', option?.email);
      }
    } catch (error) {
      console.error(error);
      this.caseData.assigned = { ...this.caseData.assigned };
    }
  }

  async handlePriorityChange(option) {
    if (this.caseData.priority.id === option.id) return;

    try {
      const res = await this.casesService.updatePriority(this.caseData.id, option.id).toPromise();
      if (res) {
        this.showNotification();
        this.caseUpdated.emit(this.caseData);
        this.utilService.ga('cases', 'desk-click-edit-priority');
      }
    } catch (error) {
      console.error(error);
      this.caseData.priority = { ...this.caseData.priority };
    }
  }

  async handleUpdateCaseType() {
    const caseType = this.caseForm.get('case_type').value;
    if (this.caseData.case_type.id === caseType.id) return;

    try {
      const res = await this.casesService.updateType(this.caseData.id, caseType).toPromise();
      if (res) {
        this.showNotification();
        this.caseUpdated.emit(this.caseData);
        this.utilService.ga('cases', 'desk-click-edit-types');
      }
    } catch (error) {
      console.error(error);
      this.caseData.case_type = { ...this.caseData.case_type };
    }
  }

  handleEditCase() {
    const caseToSave = {
      ...this.caseData,
      ...this.caseForm.value,
    };
    this.subscriptions.add(
      this.casesService.save(caseToSave).subscribe(() => {
        this.showNotification();
        this.caseUpdated.emit(this.caseData);
      }),
    );
  }

  openSnackBar(snackbarOptions, message) {
    this.snackbar.open(message, 'x', snackbarOptions);
  }

  showNotification() {
    const data = {
      translateKey: 'cod',
      translateValue: this.caseData.public_id,
      url: this.caseData.url_to_share,
      type: 'success',
    };

    this.alertService.handleAlert(
      'cases.commons.success_update',
      9000,
      'bottom',
      'end',
      'snackbar-panel-success-user-config',
      null,
      data,
    );
  }

  get usersToSubscribe() {
    let ids = [];
    if (this.subscribers) {
      ids = this.subscribers.map((sub) => sub.id);
    }
    ids.push(this.caseData.author.id);
    ids.push(this.caseData.assigned.id);
    return this.users.filter((user) => !ids.includes(user.id));
  }

  get isOfTeam() {
    return this.caseData.is_of_team;
  }

  get isAssigned() {
    return this.caseData.assigned.id === this.currentUser.id;
  }

  get CanEditFollowers() {
    return this.skipValidation || this.isSubscriber;
  }
}
