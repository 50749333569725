import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';

@Component({
  selector: 'mh-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends CoreLibModalComponent implements OnInit {

  showPassword = false;
  showRepeatPassword = false;

  changePassForm = this.fb.group({
    password: ['', [Validators.required]],
    repeatPassword: ['', [Validators.required]],
  });

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private usersManagmentService: UsersManagmentService,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
  }

  changePassword() {
    if (this.passwordControl?.value === this.repeatPasswordControl?.value) {
      this.usersManagmentService.changePassword(this.data.userIds, this.passwordControl.value).subscribe((value) => {
        this.close(value);
      });
    }
  }

  get passwordControl() {
    return this.changePassForm.get('password');
  }

  get repeatPasswordControl() {
    return this.changePassForm.get('repeatPassword');
  }

}
