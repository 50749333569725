import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { SurveyWrapperService } from 'app/shared/surveys/survey-wrapper/survey-wrapper.service';
import { UtilService } from 'app/shared/util.service';
import { Subscription } from 'rxjs';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';

@Component({
  selector: 'mh-alert-setup',
  templateUrl: './alert-setup.component.html',
  styleUrls: ['./alert-setup.component.scss']
})
export class AlertSetupComponent extends CoreLibModalComponent implements OnInit, OnDestroy {

  readonly PRODUCTS = ['followup', 'onsite', 'online'];
  qualifications = ['all', 'detractor', 'passive', 'promoter'];
  toggles = [false, false, false, false];
  subs$: Subscription;
  isIndividualActions = false;
  customers = [];
  #_configs;
  surveys = [];
  massiveAction = false;
  waiting = false;
  otas;
  isSuperAdmin = false;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private usersManagmentService: UsersManagmentService,
    private surveyService: SurveyWrapperService,
    private utilService: UtilService,
    private store: Store
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.init();
  }

  onClick(data) {
    const {id, value } = data;
    this.toggles[id] = value;
  }

  executeAction() {
    this.subs$ = this.usersManagmentService.alert_setup(this.data?.userIds || [], this.toggles, this.data?.value + 1).subscribe((response)=> {
      this.close(response);
    });
  }

  editAlertConfig() {
    const body = this.configs.reduce((acc, curr) => {
        return [...acc, ...curr.configs.map((config) => {
          return {
            "detractor": config?.toggles[1],
            "passive": config?.toggles[2],
            "promoter": config?.toggles[3],
            "active": config?.toggles[0],
            "survey_id": config['survey_id'],
            "user_id": config['user_id'],
            "customer_id": config['customer_id'],
            "ota_id": config?.ota_id || null,
          }
        })];
    }, []).filter(Boolean);
    this.close(body);
  }

  get configs() {
    return this.#_configs;
  }

  massiveActionUpdate() {
    this.massiveAction = !this.massiveAction;
    this.configs.forEach((config) => {
      config.isSelected = this.massiveAction;
      config.configs?.forEach((survey) => {
        survey.isSelected = this.massiveAction;
      });
    });
  }

  configSurveysUpdate(config) {
    config.isSelected = !config.isSelected;
    if (config.isSelected) {
      config.open = true;
    }
    config.configs.forEach((survey) => {
      survey.isSelected = config.isSelected;
    });
  }

  get someSelected() {
    const configValidation = this.configs?.some((config) => config.isSelected);
    let surveyValidation = false;
    this.configs.forEach((config) => {
      const surveySelected = config.configs?.some((survey) => survey.isSelected);
      if (surveySelected) {
        surveyValidation = true;
      }
    });

    return configValidation || surveyValidation;

  }

  async init() {
    this.isSuperAdmin = this.utilService.currentUser.isSuperAdmin();
    this.isIndividualActions = this.data?.isIndividualActions;

    if (this.isIndividualActions) {
      this.otas = await this.usersManagmentService.getOtas();
      this.waiting = true;
      this.customers = this.data?.customers;
      this.usersManagmentService.getAlertConfigSetup(this.data?.userIds, this.data?.value + 1).subscribe((result:any[]) => {
        if (result) {
          let customerIds = [...new Set(result.map(config => config.customer_id))];
          if (!this.isSuperAdmin) {
            customerIds = customerIds.filter((config) => this.currentUserCustomerIds.includes(config));
          }

          this.#_configs = customerIds.reduce((acc, curr) => {
            let configs = result.filter((config)=> config.customer_id === curr);
            const customer = this.customers.find(customer => customer.id === curr);
            configs = configs.map((config) => {
              return {
                ...config,
                isSelected: false,
                toggles: [config['active'], config['detractor'], config['passive'], config['promoter']],
                ...this.otas.find((ota) => ota.id === config.ota_id),
              }
            });
            return [...acc, { customer_id: curr, configs, ...customer, open: false, isSelected: false, }]
          }, []);
          this.waiting = false;
          if (this.data?.value + 1 !== 3) {
            this.configs.forEach((config) => {
              this.surveyService.getCustomerSurveys(config.id, this.data?.value + 1, false, null).subscribe((surveys: any[]) => {
                config.configs = config.configs.map((survey) => {
                  const surveyData = surveys.find((surv) => surv.id === survey.survey_id);
                  return {
                    ...survey,
                    ...surveyData,
                  }
                });
              });
            });
          }
        } else {
          this.waiting = false;
          this.#_configs = [];
        }
      });
    }
  }

  massiveActionGlobalUpdate(toggle, index) {
    this.configs.forEach((config) => {
      config.configs.forEach((subConfig) => {
        if (subConfig.isSelected) {
          subConfig.toggles[index] = toggle;
        }
      });
    })
  }

  get currentHotel() { return this.utilService.currentHotel; }
  get currentUserCustomerIds() { return this.utilService.currentUser.customers.map((customer) => customer.id); }

  get currentLanguage() {
    return this.store.selectSnapshot(DefaultLanguageState.languageCode);
  }

  ngOnDestroy(): void {
    if (this.subs$) {
      this.subs$.unsubscribe();
    }

  }
}
