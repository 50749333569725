import { Component, Inject, OnInit } from '@angular/core';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { UtilService } from 'app/shared/util.service';
@Component({
  selector: 'mh-product-alert-configuration',
  templateUrl: './product-alert-configuration.component.html',
  styleUrls: ['./product-alert-configuration.component.scss']
})
export class ProductAlertConfigurationComponent extends CoreLibModalComponent implements OnInit {

  readonly PRODUCTS = ['followup', 'onsite', 'online'];
  productList = [];
  isSuperAdmin = false;
  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private utilService: UtilService
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    const userCustomerIds = this.data?.user?.customers_ids;
    const userCustomers = [];
    if (userCustomerIds && this.data.isIndividualActions) {
      userCustomerIds.forEach((id) => {
        const customerFound = this.data.customers.find((customer) => customer.id === id);
        if (customerFound) {
          userCustomers.push(customerFound);
        }
      });
    }
    this.isSuperAdmin = this.utilService.currentUser.isSuperAdmin();
    this.productList = !this.data.isIndividualActions ? this.PRODUCTS : this.PRODUCTS.map((product) => {
      if (!this.isSuperAdmin) {
        return this.currentHotel[product]
      }
      return userCustomers.some((hotel) => hotel[product]);
    });
  }

  get currentHotel() { return this.utilService.currentHotel; }
}
