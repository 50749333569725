import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mh-team-members',
  templateUrl: './team-members.component.html',
  styles: [
  ]
})
export class TeamMembersComponent implements OnInit {
  @Input() users = [];
  @Input() selectedMembers = [];
  @Input() hierarchies = [];
  @Output() updateMembers = new EventEmitter();

  constructor(private translateService: TranslateService) {}
  ngOnInit(): void {
    this.users = this.users.map((user) => {
      const currentUser = this.selectedMembers.find((selectedMember) => selectedMember.id === user.id);
      return {
        ...user,
        hierarchyId: currentUser?.hierarchyId || 3,
        complete_name:
          currentUser?.hierarchyId === 1
            ? user.complete_name + (' (' + this.translateService.instant('teams.hierarchies.1') + ')')
            : user.complete_name,
      };
    });
  }

  displayChipsMembersFn(option) {
    return option?.complete_name;
  }

  inputTagMembersFilter(options, value) {
    return options.filter((option) => option?.complete_name?.toLowerCase().includes(value));
  }

  handleMembersInput(options) {
    this.selectedMembers = options;
    this.updateMembers.next(this.selectedMembers);
  }

  isNotLeader(option) {
    return option.hierarchyId !== 1;
  }
}
