<button class="tw-text-2xs tw-text-notification-text tw-bg-gray-300 tw-rounded-full tw-w-16 tw-h-5 tw-p-[1px]" (click)="goToBack()">{{'commons.exit' | translate }}</button>
    <mh-core-lib-tabs [className]="'!tw-px-10 !tw-py-3 tw-relative'" [selectedTab]="selectedTab">
        <div *ngIf="loading" class="tw-text-3xl tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-w-full tw-h-full tw-bg-[#e5e5e58f]">
            <mh-core-lib-loader-wrapper [type]="'spinner'"></mh-core-lib-loader-wrapper>
        </div>
        <mh-core-lib-tab [title]="'teams.config.data' | translate">
            <div class="tw-flex tw-flex-col tw-mt-5 tw-rounded-xl tw-bg-white tw-px-20 tw-py-14" *ngIf="team">
                <mh-team-info [team]="team" [teamName]="team.name" [teamDescription]="team.description" (emitValue)="setInfo($event)"></mh-team-info>
                <div class="tw-flex tw-justify-center tw-mt-52">
                    <mh-core-lib-button [isDisabled]="!(team.name?.length > 1 && checkName)" [text]="'forms.save_changes' | translate" [isDefault]="true" (clicked)="saveData()"></mh-core-lib-button>
                </div>
            </div>
        </mh-core-lib-tab>
        <mh-core-lib-tab [title]="'teams.config.areas' | translate">
            <div class="tw-flex tw-flex-col tw-mt-5 tw-rounded-xl tw-bg-white tw-px-20 tw-py-14" *ngIf="areas && team">
                <mh-team-areas [areas]="areas" [selectedAreas]="team.areas" (updateAreas)="setAreas($event)"></mh-team-areas>
                <div class="tw-flex tw-justify-center tw-mt-52">
                    <mh-core-lib-button [isDisabled]="team.areas?.length === 0 || !checkAreas" [text]="'forms.save_changes' | translate" [isDefault]="true" (clicked)="saveAreas()"></mh-core-lib-button>
                </div>
            </div>
        </mh-core-lib-tab>
        <mh-core-lib-tab [title]="'teams.config.members' | translate">
            <div class="tw-flex tw-flex-col tw-mt-5 tw-rounded-xl tw-bg-white tw-px-20 tw-py-14" *ngIf="selectedUsers">
                <mh-team-members [users]="users" [selectedMembers]="selectedUsers" [hierarchies]="hierarchies" (updateMembers)="setMembers($event)"></mh-team-members>
                <div class="tw-flex tw-justify-center tw-mt-52">
                    <mh-core-lib-button [isDisabled]="team?.users?.length < 2" [text]="'forms.save_changes' | translate" [isDefault]="true" (clicked)="saveUsers()"></mh-core-lib-button>
                </div>
            </div>
        </mh-core-lib-tab>
        <mh-core-lib-tab [title]="'teams.config.hierarchies' | translate">
            <div class="tw-flex tw-flex-col tw-mt-5 tw-rounded-xl tw-bg-white tw-px-20 tw-py-14" *ngIf="selectedUsers">
                <mh-team-hierarchies [selectedMembers]="selectedUsers" [hierarchies]="hierarchies" (updateHierarchies)="setHierarchies($event)"></mh-team-hierarchies>
                <div class="tw-flex tw-justify-center tw-mt-52">
                    <mh-core-lib-button [isDisabled]="hierarchiesValidation || !checkHierarchies" [text]="'forms.save_changes' | translate" [isDefault]="true" (clicked)="saveUsers()"></mh-core-lib-button>
                </div>
            </div>
        </mh-core-lib-tab>
    </mh-core-lib-tabs>
