import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CoreLibGenericConfirmationModalComponent } from 'app/core-lib/dialogs/generic-confirmation-modal/generic-confirmation-modal.component';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';
import { UtilService } from 'app/shared/util.service';
import { TeamsService } from '../teams.service';

@Component({
  selector: 'mh-teams-table',
  templateUrl: './teams-table.component.html',
})
export class TeamsTableComponent implements OnInit {
  teams;
  rawData;
  sortColumns = ['name', 'users_quantity', 'areas_quantity', 'leader_name', 'created'];
  sortColumnIds = [0, 1, 2, 3, 4];
  sortState = ['fa-sort', 'fa-sort', 'fa-sort', 'fa-sort', 'fa-sort-down'];
  loading = false;

  @Input()
  startDateLast30d;

  @Input()
  endDateLast30d;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private modalService: ModalService,
    private alertService: AlertService,
    private translate: TranslateService,
    private teamsService: TeamsService,
  ) {}

  ngOnInit(): void {
    this.loadTeams();
  }

  goToCreateTeams() {
    this.router.navigate(['create-teams'], { relativeTo: this.route });
  }

  teamId(index, option) {
    return option.id;
  }

  deleteTeam(teamId, teamName) {
    const config = {
      data: {
        text: this.translate.instant('teams.table.delete', { teamName }),
      },
    };
    const ref = this.modalService.open(CoreLibGenericConfirmationModalComponent, config);
    ref.afterClosed().subscribe((response) => {
      if (response === 'ACCEPT') {
        this.teamsService.deleteTeam(teamId).subscribe(() => {
          this.loadTeams();
          this.alertService.handleAlert(
            'user_management.actions.success',
            5000,
            'bottom',
            'end',
            'snackbar-panel-success-user-config',
          );
        });
      }
    });
  }

  loadTeams(page = 0, pageSize = 10, order_column = 'created', asc = false) {
    this.loading = true;
    this.teamsService
      .getTeamsByCustomerId(this.utilService.currentHotel.id, page, pageSize, order_column, asc)
      .subscribe((teams) => {
        this.loading = false;
        this.teams = teams.content;
        this.rawData = teams;
        this.teamsService.teams = teams.content;
      });
  }

  paginationSizeChange(event) {
    this.rawData.size = event === 0 ? this.rawData.total_elements : event;
    this.loadTeams(this.rawData.number, this.rawData.size);
  }

  pageChange(event) {
    this.rawData.number = event;
    this.loadTeams(this.rawData.number, this.rawData.size);
  }

  configTeam(teamId) {
    this.router.navigate(['config-team'], { relativeTo: this.route, queryParams: { teamId: teamId } });
  }

  sortIcon(value) {
    switch (value) {
      case 1:
        return 'fa-sort-up';
      case 2:
        return 'fa-sort-down';
    }
  }

  sort(value) {
    switch (this.sortState[value]) {
      case 'fa-sort-up':
        this.sortState[value] = this.sortIcon(2);
        this.updateSortState(value);
        return;
      case 'fa-sort':
      case 'fa-sort-down':
        this.sortState[value] = this.sortIcon(1);
        this.updateSortState(value);
        return;
    }
  }

  updateSortState(value) {
    this.sortColumnIds
      .filter((index) => index !== value)
      .forEach((stateIndex) => (this.sortState[stateIndex] = 'fa-sort'));
    const sortColumn = this.sortState.find((state) => state !== 'fa-sort');
    this.rawData.orderColumn = sortColumn ? this.sortColumns[this.sortState.indexOf(sortColumn)] : '';
    this.rawData.asc = sortColumn && sortColumn === 'fa-sort-up';
    this.loadTeams(this.rawData.number, this.rawData.size, this.rawData.orderColumn, this.rawData.asc);
  }
}
