<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-end">
    <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-font-medium">
    <h3 class="tw-text-modal-title tw-text-3sm">{{ 'user_management.actions.' + data?.action +'.title' | translate }}</h3>
  </div>

  <ng-container *ngIf="!loading else loader">
    <div class="tw-flex tw-justify-center tw-my-10">
      <i class="fas fa-exclamation-circle fa-3x tw-text-main-orange-warning"></i>
    </div>

    <div class="tw-flex tw-justify-center tw-text-center tw-text-main-text tw-text-2sm tw-h-11">
      <p>{{ 'user_management.actions.label' | translate:{ message: ('user_management.actions.' + data?.action + '.label' | translate)} }}</p>
    </div>

    <div class="tw-flex tw-justify-center tw-text-center tw-text-main-text tw-text-2sm tw-h-11 tw-font-bold">
      <p>{{ 'user_management.actions.' + data?.action + '.explanation' | translate }}</p>
    </div>

  </ng-container>
  <div class="tw-flex tw-justify-around tw-mt-3">
    <mh-core-lib-button [isSecondary]="true" [text]="('forms.cancel' | translate)" (click)="close()"></mh-core-lib-button>
    <mh-core-lib-button [isDefault]="true" [text]="('forms.accept' | translate)" (click)="executeAction()"></mh-core-lib-button>
  </div>

</div>


<ng-template #loader>
  <div class="tw-flex tw-flex-row tw-justify-center tw-w-full tw-h-[215px] tw-p-7">
    <mh-spinner-load></mh-spinner-load>
  </div>
</ng-template>
