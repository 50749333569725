import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { Subscription } from 'rxjs';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';

@Component({
  selector: 'mh-action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.scss']
})
export class ActionModalComponent extends CoreLibModalComponent implements OnInit, OnDestroy {

  subs$: Subscription;
  loading = false;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private usersManagmentService: UsersManagmentService,
    ) {
    super(dialogRef, data);
  }

  ngOnInit(): void { }

  executeAction() {
    this.loading = true;

    if (this.data?.action === 'alert_setup' && this.data?.isIndividualActions) {
      this.subs$ = this.usersManagmentService.editAlertConfig(this.data?.userIds || '', this.data?.value + 1, this.data?.response).subscribe((response) => {
        this.loading = false;
        this.close(response);
      });
    }
    this.subs$ = this.usersManagmentService[this.data?.action](this.data?.userIds || '').subscribe((response) => {
      this.loading = false;
      this.close(response);
    });
  }

  ngOnDestroy(): void {
    if (this.subs$) {
      this.subs$.unsubscribe();
    }
  }
}
