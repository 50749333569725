import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserEntrieLog, UserEntrieLogTable } from 'app/shared/model';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DateService } from 'app/shared/services/date.service';
import { UsersService } from 'app/configuration/users/users.service';

@Component({
  selector: 'mh-entries-log',
  templateUrl: './entries-log.component.html',
  styleUrls: ['./entries-log.component.scss'],
})
export class EntriesLogComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<UserEntrieLogTable>;
  displayedColumns: string[] = ['hotel', 'chain', 'name', 'lastname', 'email', 'position', 'entries', 'last_entrie'];
  endDate: string;
  showChain = new FormControl(false);
  showChainSubs: Subscription;
  startDate: string;
  filter = '';
  waiting = false;
  columnsData = [
    { column: 'hotel', translation: 'commons.hotel' },
    { column: 'chain', translation: 'commons.chain' },
    { column: 'name', translation: 'models.user.name' },
    { column: 'lastname', translation: 'models.user.lastName' },
    { column: 'email', translation: 'models.user.email' },
    { column: 'position', translation: 'models.user.job_title' },
    { column: 'entries', translation: 'configuration.users.entries_log.entries' },
    { column: 'last_entrie', translation: 'configuration.users.entries_log.last_entry' },
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private usersService: UsersService,
    private dateService: DateService
  ) { }

  ngOnInit(): void {
    this.usersService.clearShowChainDataEntries();
    this.showChainSubs = this.showChain.valueChanges
      .subscribe((value: boolean) => {
        this.usersService.setShowChainDataEntries(value);
        if ( this.currentDates.startDate && this.currentDates.endDate ) {
          this.getUserEntries(value);
        }
      });
  }

  setDateFilter(event): void {
    if (event.currentDates) {
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement)?.value || '';
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateDataSource(data: UserEntrieLogTable[]): void {
    this.dataSource = new MatTableDataSource(data);
    setTimeout(() => {
      if (this.paginator) {
        this.paginator.pageIndex = 0;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, column) => {
          if (column.toLowerCase() === 'last_entrie' && data[column]) {
            const date = data['last_entrie'];
            const splitDate = date.split('-');
            const dateObj = new Date(+splitDate[2], splitDate[1] - 1, +splitDate[0]).getTime();
            return dateObj;
          }
          return data[column];
        };
      }
    });
  }

  getUserEntries(showChain = false): void {
    if ( this.currentDates.startDate && this.currentDates.endDate) {

    this.waiting = true;
    this.filter = '';
    this.startDate = this.currentDates.startDate;
    this.endDate = this.currentDates.endDate;
    const customerIds = this.usersService.getCustomerIds(showChain);
    this.usersService.getUsersEntries(customerIds, this.startDate, this.endDate)
      .subscribe((data: UserEntrieLog[]) => {
        const dataToTable = this.mapDataToTable(data);
        this.updateDataSource(dataToTable);
        this.waiting = false;
      }, () => this.waiting = false);
    }
  }

  mapDataToTable(data: UserEntrieLog[]): UserEntrieLogTable[] {
    return data.reduce((acc, currHotel) => {
      let mappedHotel;
      this.displayedColumns.forEach((key) => {
        mappedHotel = {
          ...mappedHotel,
          [key]: key === this.columnsData[this.columnsData.length - 1].column && currHotel[key] ?
            moment(currHotel[key]).format('DD-MM-YYYY') :
            currHotel[key],
        };
      });
      return [...acc, mappedHotel];
    }, []) as UserEntrieLogTable[];
  }

  ngOnDestroy(): void {
    this.showChainSubs.unsubscribe();
    this.usersService.clearShowChainDataEntries();
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates)
    const showChain = this.showChain.value;
    this.getUserEntries(showChain);
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }
}
