
<ng-container *ngIf="isSuperAdmin else isAdminTemplate">
  <mh-core-lib-button
    [isAction]="true"
    icon="plus"
    customClass="tw-font-bold"
    (click)="showMenu= !showMenu"
  >{{ 'user_management.create_user.title' | translate }}</mh-core-lib-button>

  <div class="tw-absolute tw-py-2 tw-mt-2 tw-bg-white tw-border tw-shadow-sm tw-rounded-xl tw-overflow-auto tw-max-h-64 tw-w-40" *ngIf="showMenu">
    <ul>
      <li class="tw-px-3 tw-py-1 tw-cursor-pointer hover:tw-text-main" (click)="clickAction(action)" *ngFor="let action of actions">{{('user_management.create_user.' + action) | translate }}</li>
    </ul>
  </div>
</ng-container>

<ng-template #isAdminTemplate>
  <mh-core-lib-button
    [isAction]="true"
    icon="plus"
    customClass="tw-font-bold"
    (click)="clickAction(actions[0])"
  >{{ 'user_management.create_user.title' | translate }}</mh-core-lib-button>
</ng-template>
