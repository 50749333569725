<div class="tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-end">
    <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
  </div>

  <div class="tw-flex tw-justify-center tw-font-medium">
    <h3 class="tw-text-modal-title tw-text-3sm">{{ 'navbar.product.default.properties' | translate }}</h3>
  </div>

  <mh-accesses-table [userCustomers]="data.user?.customers_ids || []" [dataCustomers]="customers" [chains]="data?.chains" height="tw-h-[450px]" (updateHotelsToDelete)="updateHotelsToDelete($event)" (updateHotelsSelected)="updateHotels($event)"></mh-accesses-table>

  <div class="tw-flex tw-justify-center tw-text-center tw-text-main-text tw-text-2sm tw-font-bold">
    <p>{{ 'user_management.actions.assign_properties.explanation' | translate }}</p>
  </div>

  <div class="tw-flex tw-justify-around tw-my-5 tw-px-40">
    <mh-core-lib-button [isSecondary]="true" [text]="('forms.cancel' | translate)" (click)="close()"></mh-core-lib-button>
    <mh-core-lib-button [isDefault]="true" [text]="('forms.save' | translate)" (click)="addAccesses()"></mh-core-lib-button>
  </div>

</div>
