import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TeamsService } from '../../teams.service';

@Component({
  selector: 'mh-team-info',
  templateUrl: './team-info.component.html',
  styles: [
  ]
})
export class TeamInfoComponent implements OnInit, OnDestroy {
  @Input() teamName = '';
  @Input() teamDescription = '';
  @Output() emitValue = new EventEmitter();

  form: FormGroup;
  checkName;
  teamsName = [];

  subscription = new Subscription();

  constructor(private fb: FormBuilder, private teamsService: TeamsService) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      teamName: [this.teamName],
      teamDescription: [this.teamDescription],
    });

    this.teamsName = this.teamsService.teams.map((team) => team.name).filter((teamName) => this.teamName != teamName);

    this.subscription = this.form.valueChanges.pipe(debounceTime(300)).subscribe(async (values) => {
      this.checkName = !this.teamsName.includes(values.teamName);
      this.emitValue.next({ ...values, checkName: this.checkName });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
