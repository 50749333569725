import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mh-user-accesses',
  templateUrl: './user-accesses.component.html',
})
export class UserAccessesComponent {
  @Output() updateIndex = new EventEmitter();
  @Output() saveConfig = new EventEmitter();
  @Output() emitCurrentConfig = new EventEmitter();

  @Input() config;
  @Input() progressRate;

  clickItem(item, parent, grandparent) {
    if (item.childrens?.length > 0) {
      item.allSelected = !item.allSelected;
      item.childrens.forEach((subItem) => {
        if (subItem.childrens?.length > 0) {
          subItem.allSelected = item.allSelected;
          subItem.childrens.forEach((subSubitem) => (subSubitem.active = item.allSelected));
        } else {
          subItem.active = item.allSelected;
        }
      });
    } else {
      item.active = !item.active;
    }

    if (parent && !grandparent) {
      const someFalse = parent.childrens.map((child) => child.active).some((child) => child === false);
      parent.allSelected = !someFalse;
    } else if (parent && grandparent) {
      const someFalse = parent.childrens.map((child) => child.active).some((child) => child === false);
      parent.allSelected = !someFalse;

      const someParentFalse = grandparent.childrens
        .map((parentItem) => parentItem.allSelected)
        .some((parentItem) => parentItem === false);
      grandparent.allSelected = !someParentFalse;
    }
  }

  updateIndexEmit(value) {
    if (!value) {
      this.updateIndex.next(value);
      this.emitCurrentConfig.next(this.config);
    } else {
      this.saveConfig.next(this.config);
    }
  }

  emitConfig() {
    this.saveConfig.next(this.config);
  }

  trackByItem(index, item) {
    return item.slug;
  }

  get configMenu() {
    return this.config ? this.config.filter((item) => item.slug !== 'dashboard') : [];
  }
}
