<button class="tw-text-2xs tw-text-notification-text tw-bg-gray-300 tw-rounded-full tw-w-16 tw-h-5 tw-p-[1px]" (click)="goToBack()">{{'commons.exit' | translate }}</button>

<div class="tw-flex tw-flex-col tw-mt-5 tw-rounded-xl tw-bg-white tw-p-14">
    <ng-container [ngSwitch]="index">
        <ng-container *ngSwitchCase="createUserSteps.searchByEmail" [ngTemplateOutlet]="searchByEmail"/>
        <ng-container *ngSwitchCase="createUserSteps.createFrom" [ngTemplateOutlet]="createUser"/>
        <ng-container *ngSwitchCase="createUserSteps.hotelAccesses" [ngTemplateOutlet]="hotelAccesses"/>
        <ng-container *ngSwitchCase="createUserSteps.productAccesses" [ngTemplateOutlet]="productAccesses"/>
        <ng-container *ngSwitchCase="0">
            <mh-confirm-create [data]="createdUser" (emitConfirm)="confirm($event)"></mh-confirm-create>
        </ng-container>
    </ng-container>

    <ng-template #searchByEmail>
        <mh-search-by-email-modal [email]="email" [dataCustomers]="customers" [progressRate]="(index - 1) * progressRate" (emitEmail)="getUserByEmail($event)"></mh-search-by-email-modal>
    </ng-template>
    
    <ng-template #createUser>
        <mh-personal-information [progressRate]="(index - 1) * progressRate" [data]="user ? user : { email }" (updateIndex)="updateIndex($event)" (updateUser)="emitUser($event)" ></mh-personal-information>
    </ng-template>

    <ng-template #hotelAccesses>
        <mh-accesses-table [progressRate]="(index - 1) * progressRate" [dataCustomers]="customers" [chains]="chains" [userCustomers]="currentHotelsSelecteds" (updateIndex)="updateIndex($event)" (updateHotelsSelected)="updateHotelsSelected($event)"></mh-accesses-table>
    </ng-template>

    <ng-template #productAccesses>
        <mh-user-accesses [progressRate]="(index - 1) * progressRate" [config]="config || userAccesses" (saveConfig)="save($event)" (updateIndex)="updateIndex($event)" (emitCurrentConfig)="currentConfig($event)"></mh-user-accesses>
    </ng-template>
</div>