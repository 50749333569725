<div class="tw-mt-10 tw-flex tw-flex-col">
    <form [formGroup]="form">
        <div class="tw-flex tw-flex-col tw-w-80">
            <label class="tw-text-main-text tw-text-xs" >{{ 'teams.create.team-name' | translate | uppercase }}</label>
            <input class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font" type="text" formControlName="teamName" />
        </div>
        <p class="tw-mt-2 tw-text-notification-bg-error tw-font-bold" *ngIf="checkName === false">
            {{ 'teams.errors.name' | translate }}
        </p>
        <div class="tw-flex tw-flex-col tw-w-80 tw-mt-10">
            <label class="tw-text-main-text tw-text-xs" >{{ 'teams.create.description' | translate | uppercase }}</label>
            <textarea class="tw-h-[133px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font" [placeholder]="'teams.create.description-example' | translate" formControlName="teamDescription"></textarea>
        </div>
    </form>
</div>