import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'mh-add-accesses',
  templateUrl: './add-accesses.component.html',
  styleUrls: ['./add-accesses.component.scss']
})
export class AddAccessesComponent extends CoreLibModalComponent implements OnInit {

  hotelsSelecteds = [];
  hotelsToDelete = [];

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private userManagementService: UsersManagmentService
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void { }

  updateHotels(hotels) {
    this.hotelsSelecteds = hotels;
  }

  updateHotelsToDelete(hotels) {
    this.hotelsToDelete = hotels;
  }

  addAccesses() {
    const propertiesId = this.hotels.filter(Boolean).map((hotel) => hotel?.id);
    const propertiesToDelete = this.hotelsDelete.filter(Boolean).map((hotel) => hotel?.id);

    forkJoin({
      add: this.userManagementService.assign_properties(this.data.userIds, propertiesId),
      delete: this.userManagementService.revokeProperties(this.data.userIds, propertiesToDelete),
    }).subscribe(( result: { add: any, delete: any}) => {
      this.close(result.add || result.delete);
    });
  }

  get hotels() {
    return this.hotelsSelecteds;
  }

  get hotelsDelete() {
    return this.hotelsToDelete;
  }

  get customers() {
    return this.data.customers;
  }
}
