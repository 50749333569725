export class TagArea{
  id: number;
  name: string;
  clasification: TagAreaClasification;

  constructor(clasification){
    this.clasification = clasification;
  }
}


export class TagAreaClasification{
  id: number;
  name: string;
  constructor(id,name){
    this.id = id;
    this.name = name;
  }
}

