import { Component, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'mh-filter-users',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  showFilter = false;
  #chains = [];
  #customers = [];

  chainsToUpdate = [];
  customersToUpdate = [];

  clean = false;
  @Output() onUpdateChains: EventEmitter<number[]> = new EventEmitter();
  @Output() onUpdateCustomers: EventEmitter<number[]> = new EventEmitter();
  @Output() onFilter: EventEmitter<boolean> = new EventEmitter();

  @Input() set chains(value) {
    this.#chains = value;   
  };

  get chains () {
    return this.#chains;
  }

  @Input() set customers(value) {
    this.#customers = value;
  };

  get customers () {
    return this.#customers;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target) && !event.target.classList.value.includes('fa-times')) {
      this.showFilter = false;
    }
  }

  constructor(
    private eRef: ElementRef,
  ) { }

  filter() {
    this.showFilter = false;
    this.onFilter.emit(true);
  }

  cleanFilter() {
    this.customers = this.customers.map((customer) => ({...customer, checked: false }));
    this.chains = this.chains.map((chain) => ({...chain, checked: false }));
    this.onUpdateCustomers.next([]);
    this.clean = !this.clean;
  }

}
