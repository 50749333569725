<article class="tw-flex">
  <div class="tw-self-center">
    <ng-container *ngIf="smallButton">
      <button (click)="open = !open"><i class="fas fa-cog"></i></button>
    </ng-container>
    <ng-container *ngIf="!smallButton">
      <mh-core-lib-button
        [isAction]="true"
        (click)="open = !open"
        >{{ 'user_management.actions.title' | translate }}</mh-core-lib-button>
      </ng-container>
    <div class="tw-absolute">
      <div class="actions-button tw-z-10" [ngClass]="{'tw-fixed tw-right-[-62px] tw-w-56 tw-mr-20': smallButton, '': !smallButton}" *ngIf="open">
        <ul>
          <ng-container *ngFor="let action of actions">
            <ng-container *ngIf="actionsForRole.includes(action)">
              <ng-container *ngTemplateOutlet="editOption; context: { action }"></ng-container>
            </ng-container>
            <ng-container *ngIf="!actionsForRole.includes(action)">
              <ng-container *ngTemplateOutlet="options; context: { action }"></ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</article>

<ng-template #editOption let-action="action">
  <mh-core-lib-actions-wrapper
      slug="user_managment_actions"
    >
    <li class="actions-button-options" (click)="clickAction(action)">{{('user_management.actions.' + action + '.title') | translate }}</li>
  </mh-core-lib-actions-wrapper>
</ng-template>

<ng-template #options let-action="action">
  <li class="actions-button-options" (click)="clickAction(action)">{{('user_management.actions.' + action + '.title') | translate }}</li>
</ng-template>
