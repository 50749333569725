import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { CustomerService } from 'app/shared/customer.service';
import { UtilService } from 'app/shared/util.service';
import { CustomersState } from 'app/states/customers/customers.state';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { ActionModalComponent } from '../actions/action-modal/action-modal.component';
import { RestoreAccessComponent } from '../actions/restore-access/restore-access.component';
import { DisableUserComponent } from '../actions/disable-user/disable-user.component';
import { ChangePasswordComponent } from '../actions/change-password/change-password.component';
import { AlertSetupComponent } from '../actions/alert-setup/alert-setup.component';
import { CorecLibSuccessModalComponent } from 'app/core-lib/dialogs/success-modal/success-modal.component';
import { RoleChangeModalComponent } from '../role-change-modal/role-change-modal.component';
import { RolesActionsModalComponent } from '../roles-actions-modal/roles-actions-modal.component';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';
import { DateTime } from "luxon";


@Component({
  selector: 'mh-users-table',
  templateUrl: './users-table.component.html',
})
export class UsersTableComponent implements OnInit {
  @Select(CustomersState.currentCustomer) customers$: Observable<any>;
  customerSubs: Subscription;
  readonly USER_CONFIGURATION_PAGES = {
    'gestor-usuarios': 0,
    'registro-ingresos': 1,
    teams: 2,
  };

  currentCustomer;
  activeUsers = 0;
  inactiveUsers = 0;
  internalUsers = 0;
  users = [];
  totalElements = 0;
  totalPages = 0;
  filterStatus = 'active';
  textFilter = '';
  currentPage = '0';
  paginationSize = '50';
  selectedUsers;
  checkedAllUsers = false;

  search = new FormControl('');
  searchSubs$: Subscription;

  customers = [];
  customersFiltered = [];
  chains = [];
  chainsToGet = [];
  customersToGet = [];
  waiting = false;
  corporativeData;
  customersToFilter = [];
  chainsToFilter = [];
  sortColumns = ['name', 'customer_count', 'chain_count', 'role', 'creacion', 'last_login'];
  sortColumnIds = [0, 1, 2, 3, 4, 5];
  sortState = ['fa-sort', 'fa-sort', 'fa-sort', 'fa-sort', 'fa-sort-down', 'fa-sort'];
  orderColumn = '';
  asc = false;
  isSuperAdmin = false;
  isAdmin = false;
  isGlobal = false;
  selectedTab = 0;
  isFirstApiCallDone = false;
  roles = [];
  startDateLast30d;
  endDateLast30d;

  form = this.fb.group({
    users: this.fb.array([]),
  });

  userSubs$: Subscription;

  constructor(
    private usersManagmentService: UsersManagmentService,
    private fb: FormBuilder,
    private modalService: ModalService,
    private customerService: CustomerService,
    private utilService: UtilService,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.isGlobal = this.router.url.includes('gestor-usuarios-global');
    
    if (!this.isGlobal) {
      this.currentCustomer = this.utilService.currentHotel.id;
    }
    this.isSuperAdmin = this.utilService.currentUser.isSuperAdmin() && this.isGlobal;
    this.isAdmin =
      this.utilService.currentUser.isAdmin() || (this.utilService.currentUser.isSuperAdmin() && !this.isGlobal);
    this.initListeners();
    this.fetchUsers();
    const currentDate = DateTime.now();
    this.endDateLast30d = currentDate.toFormat("yyyy-MM-dd");
    this.startDateLast30d = currentDate.minus({ days: 30 }).toFormat("yyyy-MM-dd");
  }

  fetchUsers() {
    this.waiting = true;

    if (!this.utilService.currentUser.isSuperAdmin()) {
      this.currentCustomer = this.utilService.currentHotel.id;
    }

    const customerFunction = this.isGlobal ? this.usersManagmentService.getAllSimpleCustomers() : of([]);

    forkJoin({
      users: this.usersManagmentService.paginate(
        this.filterStatus,
        this.currentPage,
        this.paginationSize === '0' ? String(this.getTotalByStatus()) : this.paginationSize,
        this.orderColumn,
        this.asc,
        this.textFilter,
        this.currentCustomer,
      ),
      usersCount: this.usersManagmentService.getUsersCount(this.customersToGet.join(',') || this.currentCustomer),
      customers: customerFunction,
      roles: this.usersManagmentService.getRoles(),
    }).subscribe(
      ({ users, usersCount, customers, roles }: { users: any; usersCount: any; customers: any; roles: any }) => {
        this.waiting = false;
        if (this.utilService.currentUser.isSuperAdmin()) {
          this.customers = customers;
        } else {
          const currentUserCustomerIds = this.utilService.currentUser.customers.map((customer) => customer.id);
          this.customers = customers.filter((customer) => currentUserCustomerIds.includes(customer.id));
        }        

        this.chains = this.customers.reduce((prev, curr) => {
          const chainToPush = curr.chain_property?.chain_id;
          if (chainToPush && !prev.find((chain) => chain.id === chainToPush.id)) {
            prev = [...prev, chainToPush];
          }
          return prev;
        }, []);

        this.getUsersData(users);
        this.activeUsers = usersCount.active_users;
        this.inactiveUsers = usersCount.inactive_users;
        this.internalUsers = usersCount.internal_users;
        this.cd.detectChanges();

        if (roles) {
          this.roles = roles;
        }

        this.isFirstApiCallDone = true;
      },
      () => {
        this.waiting = false;
        this.isFirstApiCallDone = true;
      },
    );
  }

  updateUsers() {
    this.waiting = true;
    this.usersManagmentService
      .paginate(
        this.filterStatus,
        this.currentPage,
        this.paginationSize === '0' ? String(this.getTotalByStatus()) : this.paginationSize,
        this.orderColumn,
        this.asc,
        this.textFilter,
        this.customersToGet.join(',') || this.currentCustomer,
        this.chainsToGet.join(','),
      )
      .subscribe((users) => {
        this.getUsersData(users);
        this.updateCount();
        this.waiting = false;
      });
  }

  getUsersData(users) {
    this.users = users.content;
    this.totalElements = users.total_elements;
    this.totalPages = users.total_pages;
    this.users = this.users.map((user) => {
      let chainArray = [];
      if (user && !user.customers_ids) {
        return user;
      }
      if (user.customers_ids?.length === 1) {
        user.chain = [0];
      } else {
        if (user.customers_ids?.length > 0) {
          user.customers_ids.forEach((customer) => {
            const chain = this.customers.find((cus) => cus.id === customer);
            const chainId = chain?.chain_property?.chain_id?.id;
            if (chainId) {
              chainArray.push(chainId);
            }
          });
          chainArray = [...new Set(chainArray)];
          user.chain = chainArray;
        }
      }

      return user;
    });

    this.form.setControl('users', this.fb.array(new Array(this.users.length).fill(false)));
    if (!this.userSubs$) {
      this.userSubs$ = this.form.valueChanges.subscribe((value) => {
        this.selectedUsers = value.users.reduce((prev: any[], curr, idx) => {
          if (curr) {
            prev = [...prev, this.users[idx]];
          }
          return prev;
        }, []);
      });
    }
  }

  filterUsers(status) {
    this.filterStatus = status;
    this.currentPage = '0';
    this.updateUsers();
  }

  initListeners() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.selectedTab = +this.USER_CONFIGURATION_PAGES[params.page];
    });

    this.searchSubs$ = this.search.valueChanges
      .pipe(
        map((value) => value.trim()),
        distinctUntilChanged(),
      )
      .subscribe((val) => {
        this.textFilter = val ? val : '';
        this.updateUsers();
      });

    this.customerService.findCorporativeClientById(this.currentHotelId).subscribe((result) => {
      this.corporativeData = result;
    });

    if (!this.utilService.currentUser.isSuperAdmin() || !this.isGlobal) {
      this.customerSubs = this.customers$.subscribe((response) => {
        if (response) {
          this.currentCustomer = response.id;
          if (this.isFirstApiCallDone) {
            this.fetchUsers();
          }
        }
      });
    }
  }

  pageChange(page) {
    this.currentPage = '' + page;
    this.updateUsers();
  }

  paginationSizeChange(size) {
    this.paginationSize = String(size);
    this.currentPage = '0';
    this.updateUsers();
  }

  getTotalByStatus() {
    switch (this.filterStatus) {
      case 'active':
      default:
        return this.activeUsers;
      case 'internal':
        return this.internalUsers;
      case 'inactive':
        return this.inactiveUsers;
    }
  }

  updateChainsToGet(chainIds) {
    this.customersFiltered =
      chainIds.length === 0
        ? this.customers
        : this.customers.filter((customer) => chainIds.includes(customer.chain_property?.chain_id?.id));
    this.chainsToFilter = chainIds;
    this.cd.detectChanges();
  }

  updateCustomersToGet(customersIds) {
    this.customersToFilter = customersIds;
    if (customersIds.length === 0) {
      this.customers = this.customers.map((customer) => ({ ...customer, checked: false }));
    }
  }

  filter() {
    this.customersToGet =
      this.chainsToFilter?.length > 0 && this.customersToFilter?.length === 0
        ? this.chainsToFilter.reduce((acc, curr) => {
            const hotels = this.customers.filter((customer) => customer.chain_property?.chain_id.id === curr);
            acc = [...acc, ...hotels.map((hotel) => hotel.id)];
            return acc;
          }, [])
        : this.customersToFilter;
    this.chainsToGet = this.chainsToFilter;
    this.updateUsers();
  }

  selectAllUsers(value) {
    this.form.setControl('users', this.fb.array(new Array(this.users.length).fill(value.target.checked)));
  }

  ngOnDestroy(): void {
    this.searchSubs$.unsubscribe();
    if (this.userSubs$) {
      this.userSubs$.unsubscribe();
    }

    if (this.customerSubs) {
      this.customerSubs.unsubscribe();
    }
  }

  actionClicket(data, isIndividualActions = false) {
    const { action, user } = data;
    if (action) {
      const userIds = user ? user.id : this.selectedUsers.map((selectedUser) => selectedUser.id).join(',');
      let modalComponent;
      let modalClass;
      if (['delete_access', 'activate_user'].includes(action)) {
        modalComponent = ActionModalComponent;
      } else if (action === 'restore_access') {
        if (isIndividualActions) {
          modalComponent = RestoreAccessComponent;
          modalClass = ['overlay-panel', '!tw-h-auto'];
        } else {
          modalComponent = ActionModalComponent;
        }
      } else if (action === 'disable_user') {
        modalComponent = isIndividualActions ? DisableUserComponent : ActionModalComponent;
      } else if (action === 'alert_setup' || action === 'report_setup') {
        this.router.navigate(['/perfil'], {
          queryParams: { page: 'configuration', users: userIds, isGlobal: this.isGlobal, setupType: action, userEmail: user.email },
        });
        return;
      } else if (action === 'change_password') {
        modalComponent = ChangePasswordComponent;
        modalClass = ['overlay-panel', 'sm'];
      } else if (action === 'edit') {
        this.router.navigate(['config-user'], {
          relativeTo: this.activatedRoute,
          queryParams: { userEmail: user.email },
        });
        return;
      } else if (action === 'assign_properties') {
        this.router.navigate(['config-user'], {
          relativeTo: this.activatedRoute,
          queryParams: { userIds },
        });
        return;
      } else if (action === 'manage_role') {
        modalComponent = RoleChangeModalComponent;
        modalClass = ['overlay-panel', 'lg'];
      }
      const dialogRef = this.modalService.open(
        modalComponent,
        {
          data: {
            userIds,
            action,
            corporativeData: this.corporativeData,
            customers: this.customers,
            user: user,
            isIndividualActions,
            chains: this.chains,
          },
        },
        modalClass,
      );
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((value) => {
          if (
            value &&
            ['activate_user', 'restore_access', 'delete_access', 'change_password', 'edit'].includes(action)
          ) {
            this.launchSuccessModal(
              action === 'restore_access'
                ? this.translateService.instant('user_management.actions.restore_access.success')
                : null,
            );
          } else if (value && action === 'disable_user') {
            if (isIndividualActions) {
              this.updateUsers();
            } else {
              this.launchSuccessModal();
            }
          } else if (action === 'alert_setup' && [0, 1, 2].includes(value)) {
            const setupAlertDialogRef = this.modalService.open(
              AlertSetupComponent,
              { data: { userIds, value, isIndividualActions, customers: this.customers } },
              ['overlay-panel', isIndividualActions ? 'xlg' : 'lg'],
            );
            setupAlertDialogRef
              .afterClosed()
              .pipe(take(1))
              .subscribe((response) => {
                if (response && isIndividualActions) {
                  const dialogRef = this.modalService.open(
                    ActionModalComponent,
                    { data: { userIds, action, value, user: user, isIndividualActions, response } },
                    modalClass,
                  );
                  dialogRef
                    .afterClosed()
                    .pipe(take(1))
                    .subscribe((value) => {
                      if (value) {
                        this.launchSuccessModal();
                      }
                    });
                } else {
                  if (response) {
                    this.launchSuccessModal();
                  }
                }
              });
          } else if (action === 'assign_properties' && value?.length > 0) {
            this.launchSuccessModal();
          } else if (action === 'manage_role') {
            if (value) {
              this.alertService.handleAlert(
                'user_management.actions.success',
                5000,
                'bottom',
                'end',
                'snackbar-panel-success-user-config',
              );
            }
            this.updateUsers();
          }
        });
    }
  }

  launchSuccessModal(title = '') {
    const succesDialogRef = this.modalService.open(CorecLibSuccessModalComponent, title ? { data: { title } } : null, [
      'overlay-panel',
      'sm',
    ]);
    succesDialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((_) => this.updateUsers());
  }

  get currentHotelId() {
    return this.utilService.currentHotel.id;
  }

  sortIcon(value) {
    switch (value) {
      case 1:
        return 'fa-sort-up';
      case 2:
        return 'fa-sort-down';
    }
  }

  sort(value) {
    switch (this.sortState[value]) {
      case 'fa-sort-up':
        this.sortState[value] = this.sortIcon(2);
        this.updateSortState(value);
        return;
      case 'fa-sort':
      case 'fa-sort-down':
        this.sortState[value] = this.sortIcon(1);
        this.updateSortState(value);
        return;
    }
  }

  updateSortState(value) {
    this.sortColumnIds
      .filter((index) => index !== value)
      .forEach((stateIndex) => (this.sortState[stateIndex] = 'fa-sort'));
    const sortColumn = this.sortState.find((value) => value !== 'fa-sort');
    this.orderColumn = sortColumn ? this.sortColumns[this.sortState.indexOf(sortColumn)] : '';
    this.asc = sortColumn && sortColumn === 'fa-sort-up';
    this.updateUsers();
  }

  updateCount() {
    this.usersManagmentService
      .getUsersCount(this.customersToGet.join(',') || this.currentCustomer)
      .subscribe((usersCount: { active_users: number; inactive_users: number; internal_users: number }) => {
        if (usersCount) {
          this.activeUsers = usersCount.active_users;
          this.inactiveUsers = usersCount.inactive_users;
          this.internalUsers = usersCount.internal_users;
        }
      });
  }

  get actions() {
    let actions = [];
    if (this.filterStatus === 'inactive') {
      actions = ['activate_user', 'alert_setup', 'assign_properties', 'delete_access'];
    } else if (this.filterStatus === 'internal') {
      actions = ['restore_access', 'disable_user'];
    } else {
      actions = ['disable_user', 'restore_access', 'alert_setup', 'report_setup', 'assign_properties', 'delete_access', 'manage_role'];
    }

    if (!this.isSuperAdmin) {
      actions = actions.filter((action) => action !== 'delete_access');
    }
    return actions;
  }

  get individualActions() {
    let actions = [];
    if (this.filterStatus === 'inactive') {
      if (this.isGlobal) {
        actions = ['activate_user', 'edit'];
      } else {
        actions = ['activate_user', 'edit', 'alert_setup', 'report_setup'];
      }
    } else if (this.filterStatus === 'internal') {
      actions = ['edit', 'restore_access', 'disable_user'];
    } else {
      actions = ['edit', 'restore_access', 'alert_setup', 'report_setup', 'disable_user'];
    }

    return actions;
  }

  setRole($event, user) {
    const roleId = +user.user_role.id;
    const newRole = this.roles.find((role) => role.id === roleId);
    if (newRole) {
      this.usersManagmentService.updateUser({ ...user, user_role: newRole }, user.id).subscribe(() => {
          this.updateUsers();
          this.alertService.handleAlert(
            'user_management.actions.success',
            5000,
            'bottom',
            'end',
            'snackbar-panel-success-user-config',
          );
        },
        () => {
          this.alertService.handleAlert('user_management.actions.error', 5000, 'bottom', 'end', 'snackbar-panel-error');
        },
      );
    }
  }

  trackByFn(index, item): number {
    return item.id;
  }

  openRolesAndActionsModal() {
    this.modalService.open(RolesActionsModalComponent, null, ['overlay-panel', 'xxlg', '!tw-h-auto']);
  }
}
