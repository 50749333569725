<p class="tw-text-main-text tw-font-medium">{{ title }}</p>
<mh-core-lib-searcher class="input-search" [formControl]="control" [placeholder]="('user_management.filter.search_by_name_id') | translate" widthPx="130"></mh-core-lib-searcher>
<div class="tw-h-48 tw-mt-2 tw-overflow-y-auto">
  <div class="tw-flex tw-flex-row"  *ngIf="control.value.length === 0">
    <input type="checkbox" class="tw-border-2 tw-rounded-sm" [id]="type + 0" [value]="0" [checked]="showAll" (click)="setShowAll()"/><p class="tw-text-small tw-ml-1">{{ 'commons.alls' | translate }}</p>
  </div>
  <ng-container *ngIf="filteredList?.length > 0">
    <div class="tw-flex tw-flex-row" *ngFor="let item of filteredList; index as i">
      <input type="checkbox" class="tw-border-2 tw-rounded-sm" [checked]="item.checked" (click)="activate(item.id)"/><p class="tw-text-small tw-ml-1">{{ item.name }}</p>
    </div>
  </ng-container>
</div>
