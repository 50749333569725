<div class="tw-p-4 filters d-flex justify-content-between">
  <div class="inline-block filter">
    <mh-core-lib-datepicker
      [currentDates]="currentDates"
      [currentFilter]="currentFilterDate"
      (datepickerUpdated)="handleDatepickerUpdated($event)">
    </mh-core-lib-datepicker>
  </div>
  <div class="inline-block report">
    <mh-core-lib-report-button [startDate]="startDate" [finishDate]="endDate" [isFromUserEntries]="true" [activeEmail]="false"></mh-core-lib-report-button>
  </div>
</div>
<div class="m-4">
  <div class="d-flex justify-content-between">
    <mat-form-field class="w-25" appearance="standard">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>{{ 'commons.search' | translate | titlecase }}</mat-label>
      <input matInput [(ngModel)]="filter" (keyup)="applyFilter($event)" autocomplete="off">
    </mat-form-field>
    <mat-checkbox class="pt-3 mt-4" [formControl]="showChain">{{ 'configuration.users.entries_log.show_chain_data' |
      translate }}</mat-checkbox>
  </div>
  <mh-spinner-classic class="m-5" *ngIf="waiting"></mh-spinner-classic>
  <div class="mat-elevation-z4" *ngIf="!waiting">
    <table mat-table class="w-100" [dataSource]="dataSource" matSort>
      <ng-container *ngFor="let col of columnsData" [matColumnDef]="col.column">
        <th mat-header-cell class="font-weight-bold" *matHeaderCellDef mat-sort-header>{{ col.translation | translate | titlecase }}</th>
        <td mat-cell *matCellDef="let row">{{ (row[col.column] === 0 || row[col.column]) ? row[col.column] : '-' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [colspan]="displayedColumns.length">{{ 'configuration.users.entries_log.no_data' |
          translate }}</td>
      </tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
