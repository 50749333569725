import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';

@Component({
  selector: 'mh-disable-user',
  templateUrl: './disable-user.component.html',
  styleUrls: ['./disable-user.component.scss']
})
export class DisableUserComponent extends CoreLibModalComponent implements OnInit {
  actionSuccess = false;
  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private usersManagmentService: UsersManagmentService,
    ) {
    super(dialogRef, data);
  }
  ngOnInit(): void {
  }

  disableUser() {
    this.usersManagmentService.disable_user(this.data?.userIds || '').subscribe((response) => {
      this.actionSuccess = true;
    });
  }

}
