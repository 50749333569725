import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersTableComponent } from './users-table/users-table.component';
import { CreateFlowComponent } from './create-user/create-flow/create-flow.component';
import { UserConfigComponent } from './create-user/user-config/user-config.component';
import { CreateTeamsComponent } from './teams/create-teams/create-teams.component';
import { ConfigTeamComponent } from './teams/config-team/config-team.component';

const routes: Routes = [
  {
    path: 'table',
    component: UsersTableComponent,
  },
  {
    path: 'table/create-user',
    component: CreateFlowComponent,
  },
  {
    path: 'table/config-user',
    component: UserConfigComponent,
  },
  {
    path: 'table/create-teams',
    component: CreateTeamsComponent,
  },
  {
    path: 'table/config-team',
    component: ConfigTeamComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GestorUsuariosRoutingModule {}
