<ng-container *ngIf="!isIndividualActions else individualAction">
  <div class="tw-flex tw-flex-col">
    <div class="tw-flex tw-justify-end">
      <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
    </div>

    <div class="tw-flex tw-justify-center tw-font-medium">
      <h3 class="tw-text-modal-title tw-text-3sm">{{ 'user_management.actions.alert_setup.massive_setup' | translate }}</h3>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-around tw-w-full tw-my-12">
      <ng-container *ngFor="let qualification of qualifications; index as idx">
        <div class="tw-flex tw-flex-col">
          <div class="tw-h-12 tw-flex tw-justify-center tw-content-center">
            <ng-container *ngIf="idx !== 0; else notAll">
              <p>{{ 'qualifys.' + qualification | translate | titlecase }}</p>
            </ng-container>
          </div>
          <div class="tw-flex tw-justify-center tw-content-center">
            <mh-core-lib-toggle
              [inner]="idx=== 0"
              [id]="idx"
              [value]="toggles[idx]"
              (onClick)="onClick($event)"
              ></mh-core-lib-toggle>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="tw-py-10 tw-w-full">
      <div class="tw-flex tw-justify-center tw-text-center tw-text-main-text tw-text-2sm tw-h-11">
        <p class="tw-text-modal-title tw-text-3sm">{{ 'user_management.actions.alert_setup.message' | translate }}</p>
      </div>

      <div class="tw-flex tw-justify-center tw-text-center tw-text-main-text tw-text-2sm tw-h-1 tw-font-bold tw-mt-4">
        <p class="tw-text-modal-title tw-text-3sm">{{ 'user_management.actions.alert_setup.warning' | translate }}</p>
      </div>
    </div>

    <div class="tw-flex tw-justify-around tw-mt-10">
      <mh-core-lib-button [isSecondary]="true" [text]="('forms.cancel' | translate)" (click)="close()"></mh-core-lib-button>
      <mh-core-lib-button [isDefault]="true" [text]="('forms.accept' | translate)" (click)="executeAction()"></mh-core-lib-button>
    </div>

  </div>
</ng-container>

<ng-template #notAll>
  <div [innerHtml]="'user_management.actions.alert_setup.all' | translate"></div>
</ng-template>

<ng-template #individualAction>
  <div class="tw-flex tw-flex-col">
    <div class="tw-flex tw-justify-end">
      <i class="far fa-times tw-cursor-pointer" (click)="close()"></i>
    </div>

    <div class="tw-flex tw-justify-center tw-font-medium">
      <h3 class="tw-text-modal-title tw-text-3sm">{{ 'user_management.actions.alert_setup.product_setup' | translate:{product: PRODUCTS[data?.value || 0]} | titlecase }}</h3>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-around tw-mt-11 tw-w-full">
      <div class="tw-flex tw-w-[392px] tw-flex-col tw-h-12 tw-justify-center tw-content-center">
        <p>{{ 'user_management.actions.alert_setup.massive_action' | translate | titlecase }}</p>
        <input type="checkbox" class="tw-border-2 tw-rounded-sm" [checked]="massiveAction" (change)="massiveActionUpdate()"/>
      </div>

      <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-flex-row">
          <ng-container *ngFor="let qualification of qualifications; index as idx">
            <div class="tw-h-12 tw-w-32 tw-flex tw-justify-center tw-content-center">
              <ng-container *ngIf="idx !== 0; else notAll">
                <p>{{ 'qualifys.' + qualification | translate | titlecase }}</p>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div class="tw-flex tw-flex-row" *ngIf="massiveAction">
          <ng-container *ngFor="let qualification of qualifications; index as k">
            <div class="tw-h-12 tw-w-32 tw-flex tw-justify-center tw-content-center">
                <div class="tw-flex tw-justify-center tw-content-center">
                  <mh-core-lib-toggle
                    [inner]="k=== 0"
                    [id]="k"
                    [value]="toggles[k]"
                    (onClick)="toggles[k] = !toggles[k]; massiveActionGlobalUpdate(toggles[k], k)"
                    ></mh-core-lib-toggle>
                </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="configs?.length > 0 && !waiting else noProperties">
      <div class="tw-mt-5 tw-w-full tw-pl-5 container-alerts{{massiveAction ? '-small' : ''}} tw-overflow-y-auto">
        <ng-container *ngFor="let config of configs; index as idx">
          <div class="tw-flex tw-flex-row" [ngClass]="{'tw-mt-8': idx > 0}">
            <input type="checkbox" class="tw-border-2 tw-rounded-sm" [checked]="config.isSelected" (change)="configSurveysUpdate(config)" *ngIf="massiveAction"/>
            <h3 class="tw-ml-5">{{ config?.name }}</h3>
            <i class="fas fa-chevron-{{!config.open ? 'down' : 'up'}} tw-ml-5 tw-text-main tw-cursor-pointer" (click)="config.open=!config.open"></i>
          </div>

          <div class="tw-flex tw-flex-col tw-mt-11" [hidden]="!config.open || config?.configs?.length < 1">
            <ng-container *ngFor="let survey of config.configs; index as j">
              <div class="tw-bg-fff tw-shadow-1 tw-h-[68px] tw-rounded-table tw-flex tw-flex-row tw-w-[98.5%] tw-justify-center tw-content-center tw-pt-[18px] tw-mt-5">
                <div class="tw-flex tw-grow tw-flex-col tw-h-12 tw-justify-center tw-content-center">
                  <div class="tw-flex tw-flex-row tw-ml-5 tw--mt-5">
                    <input type="checkbox" class="tw-border-2 tw-rounded-sm" [checked]="survey.isSelected" (change)="survey.isSelected = !survey.isSelected" *ngIf="massiveAction"/>
                    <p class="tw-ml-5">{{ survey?.translates | translateObject: 'title' : currentLanguage}}</p>
                    <img [src]="('https://statics.myhotel.io/logos/otas/square/' + survey?.ota_id + '.png')" alt="ota logo" class="ota-logo" *ngIf="survey?.ota_id">
                    <p class="tw-ml-5 tw-mt-1">{{ survey?.name }}</p>
                  </div>
                </div>
                <ng-container *ngFor="let qualification of qualifications; index as k">
                  <div class="tw-h-12 tw-w-32 tw-flex tw-justify-center tw-content-center">
                      <div class="tw-flex tw-justify-center tw-content-center">
                        <mh-core-lib-toggle
                          [inner]="k=== 0"
                          [id]="k"
                          [value]="survey.toggles[k]"
                          (onClick)="survey.toggles[k] = !survey.toggles[k]"
                          ></mh-core-lib-toggle>
                      </div>
                  </div>
                </ng-container>
              </div>
            </ng-container >
          </div>

        </ng-container>
      </div>
    </ng-container>

    <ng-template #noProperties>
      <div class="container-alerts{{massiveAction ? '-small' : ''}}" *ngIf="!waiting"></div>
    </ng-template>

    <div class="tw-flex tw-justify-center tw-gap-6 tw-mt-10" *ngIf="!waiting">
      <mh-core-lib-button [isSecondary]="true" [text]="('forms.cancel' | translate)" (click)="close()"></mh-core-lib-button>
      <mh-core-lib-button [isDefault]="true" [text]="('forms.save' | translate)" [hidden]="configs?.length === 0" (click)="editAlertConfig()"></mh-core-lib-button>
    </div>
    <div class="tw-flex tw-flex-row tw-justify-center tw-w-full tw-p-7" [hidden]="!waiting">
      <mh-spinner-load></mh-spinner-load>
    </div>

  </div>
</ng-template>
