import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';

@Component({
  selector: 'mh-role-change-modal',
  templateUrl: './role-change-modal.component.html',
  styleUrls: ['./role-change-modal.component.scss']
})
export class RoleChangeModalComponent extends CoreLibModalComponent implements OnInit {
  users;
  roles;
  loading = false;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private usersManagmentService: UsersManagmentService,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.users = this.data?.userIds?.split(',')?.length;
    this.loading = true;
    this.usersManagmentService.getRoles().subscribe((result: any[]) => {
      this.roles = result.map((role, index) => ({ ...role, status: index === 0 }));
      this.loading = false;
    });
  }

  trackByRole(index: number, item: any): number {
    return item.id;
  }

  setRole(roleId) {
    this.roles.forEach((role) => (role.status = role.id === roleId));
  }

  saveRole() {
    const roleSelected = this.roles.find((role) => role.status);
    this.usersManagmentService.setRole(roleSelected.id, this.data?.userIds).subscribe((result) => this.close(result));
  }
}
