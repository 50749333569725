<div class="tw-flex tw-justify-between">
  <div>
    <p class="tw-text-xl tw-font-semibold" [innerHTML]="'user_management.create_user.enter_email' | translate"></p>
  </div>
  <div class="tw-flex tw-gap-1">
    <div class="tw-mb-5 tw-h-2 tw-rounded-full tw-bg-gray-200 tw-w-80 tw-mt-[10px]">
      <div class="tw-h-2 tw-rounded-full tw-bg-[#0F93CC] tw-w-[2%]"></div>
    </div>
    <p class="tw-text-xl">{{progressRate}}%</p>
  </div>
</div>
<div class="tw-flex tw-my-3">
  <p class="tw-text-xl tw-text-notification-text-time tw-font-semibold">{{ 'user_management.create_user.enter_email_sub_title' | translate }}</p>
</div> 
<div class="tw-flex tw-justify-between">
  <div class="tw-self-center tw-flex tw-flex-col tw-gap-1">
    <p class="tw-text-2sm tw-font-semibold">{{ 'themes.public.login.email' | translate }}</p>
    <input type="text" class="tw-rounded-md tw-border-2 tw-border-input-border tw-w-80 focus:tw-ring-0" [formControl]="emailControl">
    <p class="tw-text-2sm tw-font-semibold tw-text-red-500" *ngIf="emailControl.errors?.['existingUserInProperty']">{{ 'user_management.create_user.errors.existing_user' | translate }}</p>
  </div>
  <div>
    <img src="https://mh-statics.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/user-manager/message-and-email.png"/>
  </div>
</div> 
<div class="tw-flex tw-justify-center tw-mt-40">
  <mh-core-lib-button [isDefault]="true" [isDisabled]="!!emailControl?.errors" [text]="('user_management.create_user.next' | translate)" (click)="findUser()"></mh-core-lib-button>
</div>
