<ng-container *ngIf="loading; else teamsTable">
    <mh-wait-table [numberColumns]="5"></mh-wait-table>
</ng-container>

<ng-template #teamsTable>
    <section class="tw-flex tw-flex-col tw-justify-center tw-content-center tw-flex-wrap tw-gap-4 tw-h-full" *ngIf="teams === null || teams === undefined || teams?.length === 0">
        <div class="tw-mt-20">
            <h1 class="tw-text-3xl tw-font-bold tw-text-center">{{ 'teams.create.title' | translate}}</h1>
            <p class="tw-text-xl tw-font-medium tw-text-center tw-mt-4 tw-font-gray-subtitle">{{ 'teams.create.subtitle' | translate}}</p>
        </div>

        <mh-areas-images></mh-areas-images>

        <div class="tw-mt-14 tw-flex tw-justify-center tw-mb-28">
            <mh-core-lib-button [isSecondary]="true" [text]="'teams.create.action' | translate" (clicked)="goToCreateTeams()"></mh-core-lib-button>
        </div>
    </section>

    <section class="tw-flex tw-flex-col tw-gap-4 tw-px-8 tw-py-4" *ngIf="teams?.length > 0">
        <div class="tw-flex tw-justify-end tw-my-4 tw-gap-4">
            <mh-core-lib-button [isSecondary]="true" [text]="'teams.create.action' | translate" (clicked)="goToCreateTeams()"></mh-core-lib-button>
            <mh-core-lib-report-button [startDate]="startDateLast30d" [finishDate]="endDateLast30d" [isFromUserEntries]="true" [activeEmail]="false"></mh-core-lib-report-button>
        </div>
        <mh-core-lib-table [showFooter]="false" [showPaginator]="true" [currentPage]="rawData.number" [totalElements]="rawData.total_elements" [totalPages]="rawData.total_pages" [DefaultPaginationSize]="rawData.size" (paginationSize)="paginationSizeChange($event)" (pageChanged)="pageChange($event)">
            <ng-container thead>
                <ng-container *ngTemplateOutlet="head"></ng-container>
            </ng-container>
            <ng-container tbody>
                <ng-container *ngTemplateOutlet="body"></ng-container>
            </ng-container>
        </mh-core-lib-table>

        <ng-template #head>
            <tr>
                <th>
                    <div class="tw-flex tw-space-x-2">
                        <div>
                            <span>{{'teams.table.team' | translate }}</span>
                        </div>
                        <div>
                            <button (click)="sort(0)">
                                <i class="fas {{sortState[0]}}"></i>
                            </button>
                        </div>
                    </div>
                </th>
                <th>
                    <div class="tw-flex tw-space-x-2">
                        <div>
                            {{'teams.table.members' | translate }}
                        </div>
                        <div>
                            <button (click)="sort(1)">
                                <i class="fas {{sortState[1]}}"></i>
                            </button>
                        </div>
                    </div>
                </th>
                <th>
                    <div class="tw-flex tw-space-x-2">
                        <div>
                            {{'teams.table.areas' | translate }}
                        </div>
                        <div>
                            <button (click)="sort(2)">
                                <i class="fas {{sortState[2]}}"></i>
                            </button>
                        </div>
                    </div>
                </th>
                <th>
                    <div class="tw-flex tw-space-x-2">
                        <div>
                            {{'teams.table.leader' | translate }}
                        </div>
                        <div>
                            <button (click)="sort(3)">
                                <i class="fas {{sortState[3]}}"></i>
                            </button>
                        </div>
                    </div>
                </th>
                <th>
                    <div class="tw-flex tw-space-x-2">
                        <div>
                            {{'teams.table.created' | translate }}
                        </div>
                        <div>
                            <button (click)="sort(4)">
                                <i class="fas {{sortState[4]}}"></i>
                            </button>
                        </div>
                    </div>
                </th>
                <th>
                    &nbsp;
                </th>
                <th>
                    &nbsp;
                </th>
            </tr>
        </ng-template>

        <ng-template #body>
            <ng-container>
                    <tr *ngFor="let team of teams; trackBy: teamId">
                        <td class="tw-py-2">
                            <article class="tw-flex tw-space-x-3">
                                <div>
                                    {{ team.name }}
                                </div>
                            </article>
                        </td>
                        <td class="tw-py-2">
                            <article class="tw-flex">
                                <div class="tw-self-center">
                                    {{ team.users_quantity }}
                                </div>
                            </article>
                        </td>
                        <td class="tw-py-2">
                            <article class="tw-flex">
                                <div class="tw-self-center">
                                    {{ team.areas_quantity }}
                                </div>
                            </article>
                        </td>
                        <td class="tw-py-2">
                            <article class="tw-flex">
                                <div class="tw-self-center">
                                    {{ team.leader_name }}
                                </div>
                            </article>
                        </td>
                        <td class="tw-py-2">
                            <article class="tw-flex">
                                <div class="tw-self-center">
                                    {{ team.created  | date: 'd MMM y' }}
                                </div>
                            </article>
                        </td>
                        <td class="tw-py-2">
                            <article class="tw-flex">
                                <div class="tw-self-center">
                                    <i class="fal fa-cog tw-cursor-pointer" (click)="configTeam(team.id)"></i>
                                </div>
                            </article>
                        </td>
                        <td class="tw-py-2">
                            <article class="tw-flex">
                                <div class="tw-self-center">
                                    <i class="far fa-trash-alt tw-cursor-pointer" (click)="deleteTeam(team.id, team.name)"></i>
                                </div>
                            </article>
                        </td>
                    </tr>
            </ng-container>
        </ng-template>
    </section>
</ng-template>
