import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestorUsuariosComponent } from './gestor-usuarios.component';
import { ActionsComponent } from './actions/actions.component';
import { FilterComponent } from './filter/filter.component';
import { FilterSearchComponent } from './filter-search/filter-search.component';
import { ActionModalComponent } from './actions/action-modal/action-modal.component';
import { ProductAlertConfigurationComponent } from './actions/product-alert-configuration/product-alert-configuration.component';
import { AlertSetupComponent } from './actions/alert-setup/alert-setup.component';
import { AddAccessesComponent } from './actions/add-accesses/add-accesses.component';
import { HotelSearchAccessesComponent } from './actions/add-accesses/hotel-search-accesses/hotel-search-accesses.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { SearchByEmailModalComponent } from './create-user/search-by-email-modal/search-by-email-modal.component';
import { ChangePasswordComponent } from './actions/change-password/change-password.component';
import { ExistingUserModalComponent } from './create-user/existing-user-modal/existing-user-modal.component';
import { CreateUserMainModalComponent } from './create-user/create-user-main-modal/create-user-main-modal.component';
import { StepperComponent } from './create-user/stepper/stepper.component';
import { PersonalInformationComponent } from './create-user/personal-information/personal-information.component';
import { ProfilePhotoComponent } from './create-user/profile-photo/profile-photo.component';
import { AccessesTableComponent } from './actions/add-accesses/accesses-table/accesses-table.component';
import { ConfirmCreateComponent } from './create-user/confirm-create/confirm-create.component';
import { RestoreAccessComponent } from './actions/restore-access/restore-access.component';
import { DisableUserComponent } from './actions/disable-user/disable-user.component';
import { SharedModule } from 'app/shared/shared.module';
import { GestorUsuariosRoutingModule } from './gestor-usuarios-routing.module';
import { EntriesLogComponent } from './entries-list/entries-log.component';
import { CreateFlowComponent } from './create-user/create-flow/create-flow.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { UserAccessesComponent } from './create-user/user-accesses/user-accesses.component';
import { UserConfigComponent } from './create-user/user-config/user-config.component';
import { RoleChangeModalComponent } from './role-change-modal/role-change-modal.component';
import { RolesActionsModalComponent } from './roles-actions-modal/roles-actions-modal.component';
import { TeamsTableComponent } from './teams/teams-table/teams-table.component';
import { CreateTeamsComponent } from './teams/create-teams/create-teams.component';
import { AreasImagesComponent } from './teams/areas-images/areas-images.component';
import { TeamInfoComponent } from './teams/create-teams/team-info/team-info.component';
import { TeamAreasComponent } from './teams/create-teams/team-areas/team-areas.component';
import { TeamMembersComponent } from './teams/create-teams/team-members/team-members.component';
import { TeamHierarchiesComponent } from './teams/create-teams/team-hierarchies/team-hierarchies.component';
import { ConfigTeamComponent } from './teams/config-team/config-team.component';
@NgModule({
  declarations: [
    GestorUsuariosComponent,
    ActionsComponent,
    FilterComponent,
    FilterSearchComponent,
    ActionModalComponent,
    ProductAlertConfigurationComponent,
    AlertSetupComponent,
    AddAccessesComponent,
    HotelSearchAccessesComponent,
    CreateUserComponent,
    SearchByEmailModalComponent,
    ChangePasswordComponent,
    ExistingUserModalComponent,
    CreateUserMainModalComponent,
    StepperComponent,
    PersonalInformationComponent,
    ProfilePhotoComponent,
    AccessesTableComponent,
    ConfirmCreateComponent,
    RestoreAccessComponent,
    DisableUserComponent,
    EntriesLogComponent,
    CreateFlowComponent,
    UsersTableComponent,
    UserAccessesComponent,
    UserConfigComponent,
    RoleChangeModalComponent,
    RolesActionsModalComponent,
    TeamsTableComponent,
    CreateTeamsComponent,
    AreasImagesComponent,
    TeamInfoComponent,
    TeamAreasComponent,
    TeamMembersComponent,
    TeamHierarchiesComponent,
    ConfigTeamComponent,
  ],
  imports: [CommonModule, SharedModule, GestorUsuariosRoutingModule],
  exports: [PersonalInformationComponent]
})
export class GestorUsuariosModule { }
